import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckoutSteps from "../components/CheckoutSteps";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { createOrder, clearErrors } from "../actions/orderAction";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

function ConfirmPayment() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const location = new URLSearchParams(search);

  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const successStatus = location.get("success");
  const { order, error } = useSelector((state) => state.newOrder);

  useEffect(() => {
    const txnId = location.get("txnId");
    if (txnId) {
      const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));

      if (orderInfo) {
        const orderCreate = {
          shippingInfo,
          orderItems: cartItems,
          itemsPrice: orderInfo.subtotal,
          taxPrice: orderInfo.tax,
          shippingPrice: orderInfo.shippingCharges,
          totalPrice: orderInfo.totalPrice,
        };

        orderCreate.paymentInfo = {
          id: txnId,
          status: "success",
        };

        dispatch(createOrder(orderCreate));
      }
    }
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
      window.location = "/payment/confirm?success=false";
    }
    if (order) {
      sessionStorage.removeItem("orderInfo");
      localStorage.removeItem("cartItems");
      window.location = "/payment/confirm?success=true";
    }
  }, [order, error]);

  return (
    <>
      <CheckoutSteps activeStep={3} />
      <div className="bg-gray-100 my-16">
        {successStatus == "false" ? (
          <div className="bg-white md:mx-auto">
            <XCircleIcon className="h-18 stroke-2 mx-auto my-2 text-red-600" />
            <div className="text-center">
              <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                Payment Failed!
              </h3>
              <p className="my-2 text-lg">
                Any funds debited will be refunded in 2-5 business days.
              </p>
              <button
                className="mt-6 w-40 rounded-md bg-red-500 py-2 font-medium text-red-50 hover:bg-red-600"
                onClick={() => {
                  window.location = "/cart";
                }}
              >
                View Cart
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-white md:mx-auto">
            <div className="text-center">
              <CheckCircleIcon className="h-18 stroke-2 mx-auto my-2 text-green-600" />
              <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                Payment Done!
              </h3>
              <p className="my-2 text-lg">
                Your order has been placed successfully!{" "}
              </p>
              <button
                className="mt-6 w-40 rounded-md bg-red-500 py-2 font-medium text-red-50 hover:bg-red-600"
                onClick={() => {
                  window.location = "/profile";
                }}
              >
                View Profile
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ConfirmPayment;
