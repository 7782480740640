import React from "react";
import { Link } from "react-router-dom";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { addItemsToCart } from "../actions/cartAction";
import { useSelector, useDispatch } from "react-redux";
import { backend_url } from "../actions/backendUrl";
import StarRating from "./StarRating";

const Product = ({ product }) => {
  const dispatch = useDispatch();

  const options = {
    edit: false,
    color: "gray",
    size: "30px",
    activeColor: "#ffd700",
    value: product.rating,
    isHalf: true,
  };

  const addToCartHandler = () => {
    dispatch(addItemsToCart(product.urlname, 0, 1));
  };

  return (
    <Link to={`/product/${product.urlname}`}>
      <div className="z-0 flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white hover:shadow-md">
        <div className="relative mx-3 mt-3 flex h-24 md:h-40 overflow-hidden rounded-xl justify-center items-center">
          <img
            className="object-cover h-full"
            src={backend_url + `/uploads/${product.images[0][0]}`}
            alt={product.name}
          />
          <span className="absolute top-0 left-0 rounded-full bg-green-700 px-2 text-center text-sm font-medium text-white">
            {(
              ((product.actualPrice[0] - product.discountedPrice[0]) /
                product.actualPrice[0]) *
              100
            ).toFixed(0)}
            % OFF
          </span>
        </div>
        <div className="mt-2 md:mt-4 px-3 md:px-5 pb-5">
          <h5 className="text-sm md:text-base tracking-tight text-slate-900 text-ellipsis overflow-hidden h-15 md:h-12 text-center md:text-left">
            {product.name}, {product.weight[0]} {product.flavour[0]}
          </h5>

          <div className="mb-3 mt-2 md:mb-5 flex flex-col md:flex-row items-center justify-center md:justify-between">
            <p>
              <span className="text-base md:text-2xl font-bold text-slate-900 pr-1">
                ₹{product.discountedPrice[0]}
              </span>
              <span className="text-xs md:text-sm text-slate-900 line-through">
                ₹{product.actualPrice[0]}
              </span>
            </p>
            <div className="flex justify-between items-center">
              <StarRating rating={0} />
            </div>
          </div>
          <button
            // onClick={addToCartHandler}
            className="w-full flex items-center justify-center rounded-md md:px-5 py-2 md:py-2.5 text-center text-xs md:text-sm font-medium focus:outline-none focus:ring-4 focus:ring-gray-300 border border-gray-400 hover:border-black"
          >
            <ShoppingCartIcon className="h-4 md:h-5 stroke-[1.8] pr-1 md:pr-2" />
            Add to cart
          </button>
        </div>
      </div>
    </Link>
  );
};

export default Product;
