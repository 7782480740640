import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
  HomeIcon,
  BuildingOfficeIcon,
  MapPinIcon,
  PhoneIcon,
  GlobeAsiaAustraliaIcon,
} from "@heroicons/react/24/outline";
import {
  clearErrors,
  addAddress,
  editAddress,
  deleteAddress,
} from "../actions/userAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Address({ addressIndex }) {
  const dispatch = useDispatch();

  const [mode, setMode] = useState("create");
  const [showModal, setShowModal] = useState(false);

  const stateslist = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  const { user } = useSelector((state) => state.user);

  const { shippingInfo } = useSelector((state) => state.cart);
  const userAddress = useSelector((state) => state.userAddress);

  const [address, setAddress] = useState(shippingInfo.address);
  const [city, setCity] = useState(shippingInfo.city);
  const [state, setState] = useState(shippingInfo.state);
  const [country, setCountry] = useState(shippingInfo.country);
  const [pinCode, setPinCode] = useState(shippingInfo.pinCode);
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);
  const [addressId, setAddressId] = useState("");

  const [userAddresses, setUserAddresses] = useState([]);

  useEffect(() => {
    if (userAddress.error) {
      dispatch(clearErrors());
    }

    if (userAddress.success) {
      window.location.reload();
    }
  }, [userAddress.error, userAddress.success]);

  useEffect(() => {
    setCountry("India");
  }, []);

  useEffect(() => {
    if (user) {
      setUserAddresses(user.addresses);
    }
  }, [user]);

  const addAddressHandler = () => {
    setMode("create");

    setAddress("");
    setCity("");
    setState("");
    setCountry("India");
    setPinCode("");
    setPhoneNo("");
    if (phoneNo.length < 10 || phoneNo.length > 10) {
      toast.warning("Phone Number should be 10 digits long", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setMode("create");
    setShowModal(false);
  };

  const editAddressHandler = (address) => {
    setMode("edit");

    setAddressId(address._id);

    setAddress(address.address);
    setCity(address.city);
    setState(address.state);
    setCountry(address.country);
    setPinCode(address.pinCode);
    setPhoneNo(address.phoneNo);

    setShowModal(true);
  };

  const addressSubmitHandler = (e) => {
    e.preventDefault();

    if (phoneNo.length < 10 || phoneNo.length > 10) {
      toast.warning("Phone Number should be 10 digits Long", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (mode === "create") {
      dispatch(
        addAddress(user._id, {
          address,
          city,
          state,
          country,
          pinCode,
          phoneNo,
        })
      );
    } else if (mode === "edit") {
      dispatch(
        editAddress(user._id, addressId, {
          address,
          city,
          state,
          country,
          pinCode,
          phoneNo,
        })
      );
    }
  };

  const deleteAddressHandler = (addressId) => {
    dispatch(deleteAddress(user._id, addressId));
  };

  const selectAddress = (index) => {
    addressIndex(index);
  };

  return (
    <div className="w-full md:w-5/6 my-8">
      <div className="rounded-sm border border-stroke bg-white p-2">
        <ToastContainer />
        <div className="flex justify-between items-center mb-2">
          <div className="w-28"></div>
          <h1 className="text-xl font-semibold">Saved Addresses</h1>
          <button
            className="bg-gray-500 text-white hover:bg-gray-600 font-semibold text-xs px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-200"
            type="button"
            onClick={() => addAddressHandler()}
          >
            + Add Address
          </button>
        </div>

        {showModal ? (
          <div
            className="py-16 bg-gray-700/50 transition duration-150 ease-in-out z-20 fixed top-32 right-0 bottom-0 left-0 overflow-auto"
            id="modal"
          >
            <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
              <form
                className="relative py-6 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400"
                onSubmit={addressSubmitHandler}
              >
                <h1 className="text-gray-800 text-left text-xl font-bold">
                  Enter Address
                </h1>
                <div className="flex py-3 my-8 border rounded border-gray-800">
                  <HomeIcon className="w-6 h-6 mx-4" />
                  <input
                    className="w-full outline-none"
                    type="text"
                    placeholder="Address"
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>

                <div className="flex py-3 my-8 border rounded border-gray-800">
                  <BuildingOfficeIcon className="w-6 h-6 mx-4" />
                  <input
                    className="w-full outline-none"
                    type="text"
                    placeholder="City"
                    required
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>

                <div className="flex py-3 my-8 border rounded border-gray-800">
                  <GlobeAsiaAustraliaIcon className="w-6 h-6 mx-4" />
                  <select
                    required
                    value={state}
                    className="w-full outline-none"
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="">State</option>
                    {stateslist.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex py-3 my-8 border rounded border-gray-800">
                  <MapPinIcon className="w-6 h-6 mx-4" />
                  <input
                    className="w-full outline-none"
                    type="number"
                    placeholder="Pin Code"
                    required
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                  />
                </div>

                <div className="flex py-3 my-8 border rounded border-gray-800">
                  <PhoneIcon className="w-6 h-6 mx-4" />
                  <input
                    className="w-full outline-none"
                    type="number"
                    placeholder="Phone Number"
                    required
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    size="10"
                  />
                </div>

                <div className="flex items-center justify-start w-full">
                  <button
                    className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition duration-150 ease-in-out hover:bg-gray-600 bg-gray-700 rounded text-white px-8 py-2 text-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                  <button
                    className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>
                </div>
                <button
                  className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                  onClick={() => closeModal()}
                  aria-label="close modal"
                  role="button"
                >
                  <XMarkIcon className="h-5 stroke-[2.5]" />
                </button>
              </form>
            </div>
          </div>
        ) : null}

        <div className="flex flex-col gap-10">
          <div className="max-w-full overflow-x-auto">
            {userAddresses && userAddresses.length > 0 ? (
              <form onSubmit={(e) => e.preventDefault()}>
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-2 dark:bg-meta-4">
                      {addressIndex ? (
                        <th className="py-4 px-3 font-medium text-blackadmin">
                          Select
                        </th>
                      ) : (
                        <></>
                      )}
                      <th className="py-4 px-3 font-medium text-blackadmin">
                        Address
                      </th>
                      <th className="py-4 px-3 font-medium text-blackadmin">
                        City
                      </th>
                      <th className="py-4 px-3 font-medium text-blackadmin">
                        State
                      </th>
                      <th className="py-4 px-3 font-medium text-blackadmin">
                        Pin Code
                      </th>
                      <th className="py-4 px-3 font-medium text-blackadmin">
                        Phone No
                      </th>
                      <th className="py-4 px-3 font-medium text-blackadmin">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {userAddresses.map((address, index) => (
                    <tbody>
                      <tr>
                        {addressIndex ? (
                          <td className="border-b border-[#eee] py-5 px-3">
                            <input
                              type="radio"
                              name="addresses"
                              onChange={(e) => selectAddress(e.target.value)}
                              value={index}
                              id={index}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        <td className="border-b border-[#eee] py-5 px-3">
                          <label for={index}>
                            <p className="text-blackadmin">{address.address}</p>
                          </label>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-3">
                          <label for={index}>
                            <p className="text-blackadmin">{address.city}</p>
                          </label>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-3">
                          <label for={index}>
                            <p className="text-blackadmin">{address.state}</p>
                          </label>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-3">
                          <label for={index}>
                            <p className="text-blackadmin">{address.pinCode}</p>
                          </label>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-3">
                          <label for={index}>
                            <p className="text-blackadmin">{address.phoneNo}</p>
                          </label>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-3">
                          <div className="flex items-center justify-center space-x-2">
                            <button
                              className="hover:text-primary"
                              onClick={() => editAddressHandler(address)}
                            >
                              <PencilSquareIcon className="h-5 stroke-[1.5]" />
                            </button>
                            <button
                              className="hover:text-primary"
                              onClick={() =>
                                window.confirm(
                                  "Are you sure you want to delete this address?"
                                )
                                  ? deleteAddressHandler(address._id)
                                  : ""
                              }
                            >
                              <TrashIcon className="h-5 stroke-[1.5]" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </form>
            ) : (
              <div>
                <h1>No Saved Addresses Found.</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Address;
