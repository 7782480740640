import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  SAVE_SHIPPING_INFO,
} from "../constants/cartConstant";

export const cartReducer = (state = { cartItems: [], shippingInfo:{} }, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const item = action.payload;
      const isItemExist = state.cartItems.find(
        (i) =>
          i.product === item.product &&
          i.weight === item.weight &&
          i.flavour === item.flavour
      );

      if (isItemExist) {
        return {
          ...state,
          cartItems: state.cartItems.map((i) =>
            i.product === isItemExist.product &&
            i.weight === isItemExist.weight &&
            i.flavour === isItemExist.flavour
              ? item
              : i
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
          success: true,
        };
      }

    case REMOVE_CART_ITEM:
      console.log(state.cartItems);
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (i) =>
            i.product !== action.payload.product ||
            i.weight !== action.payload.weight ||
            i.flavour !== action.payload.flavour
        ),
        success: true,
      };
    case SAVE_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: action.payload,
      };

    default:
      return state;
  }
};
