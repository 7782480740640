import React from "react";
import {
  TruckIcon,
  CheckBadgeIcon,
  CreditCardIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

const CheckoutSteps = ({ activeStep }) => {
  const steps = [
    {
      label: <h2>Login/Signup</h2>,
      icon: <UserIcon className="h-6" />,
    },
    {
      label: <h2>Shipping Details</h2>,
      icon: <TruckIcon className="h-6" />,
    },
    {
      label: <h2>Confirm Order</h2>,
      icon: <CheckBadgeIcon className="h-6" />,
    },
    {
      label: <h2>Payment</h2>,
      icon: <CreditCardIcon className="h-6" />,
    },
  ];

  return (
    <>
      <div>
        <div className="w-11/12 md:w-5/6 mx-auto mt-8">
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div
              className={
                activeStep === 0
                  ? "w-0 h-2 rounded-full bg-red-500"
                  : activeStep === 1
                  ? "w-1/3 h-2 rounded-full bg-red-500"
                  : activeStep === 2
                  ? "w-2/3 h-2 rounded-full bg-red-500"
                  : "w-full h-2 rounded-full bg-red-500"
              }
            ></div>
          </div>

          <ol className="mt-2 grid grid-cols-4 text-sm font-medium text-gray-500">
            {steps.map((item, index) => (
              <li
                className={
                  "flex flex-col sm:flex-row text-center items-center sm:gap-1 " +
                  (index === 0
                    ? "sm:justify-start"
                    : index === 1
                    ? "sm:justify-center"
                    : index === 2
                    ? "sm:justify-center"
                    : "sm:justify-end") +
                  (index <= activeStep ? " text-red-600" : " text-gray-500")
                }
              >
                {item.label}
                {item.icon}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </>
  );
};

export default CheckoutSteps;
