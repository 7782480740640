import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login } from "../actions/userAction";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
import { IdentificationIcon } from "@heroicons/react/24/outline";

function Login() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const location = new URLSearchParams(search);

  const redirect = location.size != 0 ? location.get("redirect") : "profile";

  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const loginSubmit = (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    dispatch(login(email, password));
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
      if (error == "Invalid email or password") {
        toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    if (isAuthenticated) {
      window.location.pathname = "/" + redirect;
    }
  }, [dispatch, error, isAuthenticated]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <ToastContainer />
          <div className="flex items-center py-12 bg-gray-50">
            <div className="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl">
              <div className="flex flex-col md:flex-row">
                <div className="h-32 md:h-auto md:w-1/2">
                  <img
                    className="object-cover w-full h-full"
                    src="https://source.unsplash.com/user/erondu/1600x900"
                    alt="img"
                  />
                </div>
                <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                  <div className="w-full">
                    <div className="flex justify-center">
                      <IdentificationIcon className="w-16 h-16 text-red-600" />
                    </div>
                    <h1 className="mb-4 text-2xl font-bold text-center text-gray-700">
                      Sign In
                    </h1>
                    <form id="signinform" onSubmit={loginSubmit}>
                      <div className="mt-4">
                        <label className="block text-sm">Email</label>
                        <input
                          type="email"
                          id="email"
                          className="w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
                          placeholder="Email Address"
                          required
                        />
                      </div>
                      <div>
                        <label className="block mt-4 text-sm">Password</label>
                        <input
                          id="password"
                          className="w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
                          placeholder="Password"
                          type="password"
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-gray"
                        href="#"
                      >
                        Sign In
                      </button>
                    </form>
                    <div className="mt-4 text-center">
                      <p className="text-sm">
                        Don't have an account yet?
                        <a
                          href="/register"
                          className="text-red-600 hover:underline"
                        >
                          {" "}
                          Sign Up.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
