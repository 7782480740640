import React, { useState, useEffect } from "react";
import emptycart from "../static/images/emptycart.png";
import { useSelector, useDispatch } from "react-redux";
import { addItemsToCart, removeItemsFromCart } from "../actions/cartAction";
import { Link } from "react-router-dom";
import { backend_url } from "../actions/backendUrl";

const Cart = () => {
  const dispatch = useDispatch();

  const { cartItems } = useSelector((state) => state.cart);

  const [cartSum, setCartSum] = useState(0);

  const increaseQuantity = (urlname, index, quantity, stock) => {
    const qty = quantity + 1;
    if (stock <= quantity) return;

    dispatch(addItemsToCart(urlname, index, qty));
  };

  const decreaseQuantity = (urlname, index, quantity, stock) => {
    const qty = quantity - 1;
    if (quantity <= 1) return;

    dispatch(addItemsToCart(urlname, index, qty));
  };

  const removeItem = (product, weight, flavour) => {
    dispatch(removeItemsFromCart(product, weight, flavour));
  };

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      let sum = 0;
      for (let i = 0; i < cartItems.length; i++) {
        sum += cartItems[i].quantity * cartItems[i].price;
      }
      setCartSum(sum);
    }
  }, [increaseQuantity, decreaseQuantity, removeItem]);

  const checkoutHandler = () => {
    window.location = "/checkoutlogin";
  };

  return (
    <>
      {cartItems && cartItems.length > 0 ? (
        <>
          <div className="bg-gray-50 py-12">
            <h1 className="mb-10 text-center text-2xl text-red-600 font-bold">
              Cart Items
            </h1>
            <div className="mx-auto max-w-5xl justify-center px-6 lg:flex lg:space-x-6 xl:px-0 pb-8 sm:pb-0">
              <div className="rounded-lg">
                {cartItems &&
                  cartItems.map((item) => (
                    <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start">
                      <img
                        src={backend_url + `/uploads/${item.image}`}
                        alt={item.name}
                        className="rounded-lg sm:w-30 w-2/5 mx-auto"
                      />
                      <div>
                        <div className="sm:pl-6 sm:flex sm:w-full sm:justify-between">
                          <div className="mt-5 sm:mt-0 sm:pr-20 text-center sm:text-left">
                            <Link to={`/product/${item.urlname}`}>
                              <h2 className="text-lg hover:underline font-bold md:w-80 lg:w-52 xl:w-60 text-gray-900">
                                {item.name}
                              </h2>
                            </Link>
                            <p className="mt-1 text-sm text-gray-700">
                              Weight: {item.weight}
                            </p>
                            <p className="text-sm text-gray-600 ">
                              Flavour: {item.flavour}
                            </p>
                          </div>
                          <div className="mt-4 flex sm:justify-between im sm:space-y-6 sm:mt-0 sm:block sm:space-x-6  justify-center">
                            <div className="flex items-center border-gray-100">
                              <div className="flex items-center border border-gray-200 rounded">
                                <button
                                  type="button"
                                  className="w-10 h-10 leading-10 text-gray-600 transition hover:opacity-75"
                                  onClick={() =>
                                    decreaseQuantity(
                                      item.urlname,
                                      item.index,
                                      item.quantity,
                                      item.stock
                                    )
                                  }
                                >
                                  &minus;
                                </button>
                                <input
                                  readOnly
                                  type="number"
                                  id="Quantity"
                                  value={item.quantity}
                                  className="h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
                                />
                                <button
                                  type="button"
                                  className="w-10 h-10 leading-10 text-gray-600 transition hover:opacity-75"
                                  onClick={() =>
                                    increaseQuantity(
                                      item.urlname,
                                      item.index,
                                      item.quantity,
                                      item.stock
                                    )
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-between pt-5 md:pl-6">
                          <p
                            className="text-xs underline text-red-500 cursor-pointer"
                            onClick={() =>
                              removeItem(
                                item.product,
                                item.weight,
                                item.flavour
                              )
                            }
                          >
                            Remove
                          </p>
                          <p className="text-base justify-end font-black leading-none text-gray-800">
                            ₹ {item.price}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="mt-6 h-full rounded-lg border bg-white p-6 shadow-md lg:mt-0 lg:w-1/3">
                <div className="mb-2 flex justify-between">
                  <p className="text-gray-700">Subtotal</p>
                  <p className="text-gray-700">₹ {cartSum.toFixed(2)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-700">Shipping</p>
                  <p className="text-gray-700">₹ 0.00</p>
                </div>
                <hr className="my-4" />
                <div className="flex justify-between">
                  <p className="text-lg font-bold">Total</p>
                  <div className="grid">
                    <p className="mb-1 text-lg font-bold">
                      ₹ {cartSum.toFixed(2)}
                    </p>
                    <p className="text-sm text-gray-700 justify-self-end">
                      including GST
                    </p>
                  </div>
                </div>
                <button
                  onClick={checkoutHandler}
                  className="mt-6 w-full rounded-md bg-red-500 py-1.5 font-medium text-red-50 hover:bg-red-600"
                >
                  Check out
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="py-20 flex items-center justify-center">
          <div className="mx-auto">
            <div>
              <img src={emptycart}></img>
            </div>

            <div className="flex justify-center items-center my-8">
              <a
                href="/products"
                className=" rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-slate-800 focus:outline-none focus:ring-4 focus:ring-gray-300"
              >
                View All Products
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
