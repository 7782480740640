import React from "react";

function AboutUs() {
  return (
    <div className="bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50">
      <div className="py-10 md:px-24 px-10">
        <h1 className="text-2xl font-bold">About Us</h1>
        <p className="my-4 text-justify">
          Fitnesstrendz.in is India's most trusted and fastest-growing nutrition
          and online supplement store with a wide range of products for health,
          wellness, fitness & bodybuilding, making them easy to purchase &
          accessible all over India.
        </p>
        <p className="my-4 text-justify">
          Being fitness and bodybuilding enthusiasts ourselves; we know that
          nutritional supplements are an important part of this delightful
          lifestyle and by that we do know how customers think and what they
          want. Without taking any names, unlike other market players we don't
          overcharge on the name of Authenticity as we know our majority
          Customers come from the young generation who remain tight on their
          pockets.
        </p>
        <p className="my-4 text-justify">
          Addicted to sports, dedicated to athletes, and up-to-date with the
          latest sport supplements for our customers, that's who we are. We put
          in the effort to make sure the supsupplements you're getting is the
          best possible product for your needs, ultimately making your health
          and fitness goals a reality.
        </p>
        <p className="mt-4 text-justify">
          These are some key points that makes us different from other sellers.
        </p>
        <ul className="list-disc ml-8 my-2 text-justify">
          <li>
            You get all products delivered directly from us, without any
            middlemen. Additionally, we are certified by most of the major
            brands and importers for authentic supplements. This ensures that
            you always get an authentic supplement as the entire supply chain is
            controlled by us.
          </li>
          <li>
            Customer satisfaction and happiness is our top most priority, we
            deliver this by ensuring a great user experience, fast delivery, and
            high-quality products.{" "}
          </li>
          <li>
            A company run by young and enthusiastic people whose mission is to
            help India become a fitter and stronger nation and are committed to
            bringing quality products to support this cause.{" "}
          </li>
          <li>Delivering Authentic Supplements Since 2021.</li>
        </ul>
      </div>
    </div>
  );
}

export default AboutUs;
