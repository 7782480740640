import React, { useEffect, useState } from "react";

function StarRating({ rating }) {
  const [starsFill, setStarsFill] = useState([]);

  useEffect(() => {
    if (starsFill.length === 0) {
      let tempStarFill = starsFill;
      for (let i = 0; i < rating; i++) {
        if (i < rating && rating < i + 1) {
          tempStarFill.push((rating - i) * 100);
        } else tempStarFill.push(100);
      }
      for (let i = Math.ceil(rating); i < 5; i++) {
        tempStarFill.push(0);
      }
      setStarsFill(tempStarFill);
    }
  }, []);

  return (
    <div className="flex items-center">
      {starsFill && starsFill.length > 0 ? (
        starsFill.map((percentage, index) => (
          <svg
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            className="w-4 h-4 text-gray-600"
            viewBox="0 0 24 24"
          >
            <defs>
              <linearGradient id={"grad" + index}>
                <stop
                  offset={percentage + "%"}
                  stop-color="rgb(249, 115, 22)"
                />
                <stop offset="0%" stop-color="white" />
              </linearGradient>
            </defs>
            <path
              fill={"url(#grad" + index + ")"}
              d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"
            ></path>
          </svg>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

export default StarRating;
