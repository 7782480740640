import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from "../actions/cartAction";
import CheckoutSteps from "../components/CheckoutSteps";
import Address from "../components/Address";

function Shipping() {
  const dispatch = useDispatch();

  const [addressIndex, setAddressIndex] = useState(-1);

  const { shippingInfo } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);

  const shippingSubmit = (e) => {
    e.preventDefault();

    dispatch(saveShippingInfo(user.addresses[addressIndex]));
    window.location = "/order/confirm";
  };

  const getIndex = (index) => {
    setAddressIndex(index);
  };

  return (
    <>
      <CheckoutSteps activeStep={1} />
      <div className="flex flex-col text-center items-center my-8">
        <h2 className="text-2xl font-semibold text-red-600">Shipping Details</h2>
        <p className="text-lg my-4">Select an address to continue.</p>
        <Address addressIndex={getIndex} />
        {addressIndex >= 0 ? (
          <button
            className="bg-gray-500 mt-2 text-white hover:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-200"
            type="button"
            onClick={shippingSubmit}
          >
            Continue
          </button>
        ) : (
          <button
            disabled
            className="bg-gray-500 mt-2 text-white cursor-not-allowed font-bold uppercase text-sm px-6 py-3 rounded shadow outline-none"
            type="button"
          >
            Continue
          </button>
        )}
      </div>
    </>
  );
}

export default Shipping;
