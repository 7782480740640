import { useEffect } from "react";
import Home from "./pages/Home.js";
import ProductDetails from "./pages/ProductDetails.js";
import Cart from "./pages/Cart.js";
import Dashboard from "./pages/admin/Dashboard.tsx";
import Products from "./pages/AllProducts.js";
import Profile from "./pages/Profile.js";
import Shipping from "./pages/Shipping.js";
import ConfirmOrder from "./pages/ConfirmOrder.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import DefaultLayout from "./components/admin/DefaultLayout.js";
import ProductsAdmin from "./pages/admin/Products.js";
import UsersAdmin from "./pages/admin/Users.js";
import OrdersAdmin from "./pages/admin/Orders.js";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { loadUser } from "./actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import logo from "./static/images/logo.jpg";
import ProtectedRoute from "./components/ProtectedRoute.js";
import Certificates from "./pages/Certificates.js";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import TermsAndConditions from "./pages/TermsAndConditions.js";
import ShippingPolicy from "./pages/ShippingPolicy.js";
import ReturnAndRefunds from "./pages/ReturnAndRefunds.js";
import ConfirmPayment from "./pages/ConfirmPayment.js";
import ScrollToTop from "./components/ScrollToTop.js";
import AboutUs from "./pages/AboutUs.js";
import CheckoutLogin from "./pages/CheckoutLogin.js";

export default function App() {
  const dispatch = useDispatch();

  const { isAuthenticated, user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <FloatingWhatsApp
        phoneNumber="+91 79822 85335"
        accountName="FitnessTrendz"
        allowClickAway="true"
        allowEsc="true"
        avatar={logo}
      />
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route
            exact
            path="/admin"
            element={<ProtectedRoute isAdmin={true} Component={Dashboard} />}
          />
          <Route
            exact
            path="/admin/products"
            element={
              <ProtectedRoute isAdmin={true} Component={ProductsAdmin} />
            }
          />
          <Route
            exact
            path="/admin/users"
            element={<ProtectedRoute isAdmin={true} Component={UsersAdmin} />}
          />
          <Route
            exact
            path="/admin/orders"
            element={<ProtectedRoute isAdmin={true} Component={OrdersAdmin} />}
          />
        </Route>

        <Route exact path="/" element={<Home />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/certificates" element={<Certificates />} />
        <Route exact path="/product/:urlname" element={<ProductDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/shippingpolicy" element={<ShippingPolicy />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/returnandrefunds" element={<ReturnAndRefunds />} />
        <Route path="/checkoutlogin" element={<CheckoutLogin />} />
        <Route
          exact
          path="/profile"
          element={<ProtectedRoute Component={Profile} />}
        />
        <Route
          exact
          path="/shipping"
          element={<ProtectedRoute Component={Shipping} />}
        />
        <Route
          exact
          path="/order/confirm"
          element={<ProtectedRoute Component={ConfirmOrder} />}
        />
        <Route
          exact
          path="/payment/confirm"
          element={<ProtectedRoute Component={ConfirmPayment} />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}
