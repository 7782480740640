import React from "react";

const Footer = () => {
  return (
    <footer className="relative bg-sky-100 pt-8 pb-6">
      <div className="container mx-auto md:px-4">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl fonat-semibold text-red-600">
                Fitnesstrendz.in
              </h4>
            <h5 className="text-md my-2 text-gray-600">
              <span className="font-semibold">WARNING!</span> Please only
              purchase and use the products on this website If you are aged 18
              years and over. Please ensure you are in a good State of health
              and/or consult your doctor before starting a Supplement programme.
            </h5>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top">
              <div className="w-full lg:w-4/12 md:px-4 ml-auto py-4 md:py-0">
                <span className="block uppercase text-red-600 text-sm font-semibold mb-2">
                  Useful Links
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-sm"
                      href="/products"
                    >
                      All Products
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-sm"
                      href="/aboutus"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-sm"
                      href="https://blogs.fitnesstrendz.in/"
                      target="_blank"
                    >
                      Blogs
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-sm"
                      href="/certificates"
                    >
                      Certificates
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 md:px-4">
                <span className="block uppercase text-red-600 text-sm font-semibold mb-2">
                  Other Resources
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-sm"
                      href="/termsandconditions"
                    >
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-sm"
                      href="/shippingpolicy"
                    >
                      Shipping Policy
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-sm"
                      href="/returnandrefunds"
                    >
                      Return & Refunds
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-sm"
                      href="https://api.whatsapp.com/send/?phone=+917982285335&text=Hi"
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-500 font-semibold py-1">
              Copyright © <span id="get-current-year">2024 </span>
              <a
                href="https://fitnesstrendz.in/"
                className="text-gray-500 hover:text-gray-800"
              >
                Fitnesstrendz
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
