import React, { useState, useEffect, useRef } from "react";
import bannerphoto1 from "../static/images/protien powder.jpg";
import bannerphoto2 from "../static/images/pre workout 1.jpg";
import bannerphoto3 from "../static/images/cretine 1.jpg";
import bannerphoto4 from "../static/images/g1.jpg";
import CategoryCard from "../components/categoryCard";
import Product from "../components/Product";
import MetaData from "../components/MetaData";
import { getProduct } from "../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Home = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const location = new URLSearchParams(search);

  const categoryRef = useRef();

  const { loading, error, products } = useSelector((state) => state.products);

  const categories = [
    "Whey Protein",
    "Creatine",
    "Pre workout",
    "Gainer",
    "Omega 3",
    "Fat Burner",
    "Multi vitamin",
    "Isolate Protein",
  ];
  const [categoryProducts, setCategoryProducts] = useState({});

  useEffect(() => {
    if (!loading) {
      if (location.size != 0)
        categoryRef.current.scrollIntoView({ behavior: "smooth" });
      else window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [loading]);

  useEffect(() => {
    dispatch(getProduct());
  }, [dispatch]);

  useEffect(() => {
    if (products) {
      let catProd = {};

      for (let i = 0; i < Object.values(products).length; i++) {
        let cat = Object.values(products)[i].category;
        if (!(cat in catProd)) catProd[cat] = [];
        catProd[cat].push(Object.values(products)[i]);
      }
      setCategoryProducts(catProd);
    }
  }, [products]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <MetaData title="FitnessTrendz" />

          {/* <img src={bannerphoto1} className="w-full"></img> */}
          <Carousel
            infiniteLoop={true}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
          >
            <div>
              <img src={bannerphoto1} className="w-full" />
            </div>
            <div>
              <img src={bannerphoto2} className="w-full" />
            </div>
            <div>
              <img src={bannerphoto3} className="w-full" />
            </div>
            <div>
              <img src={bannerphoto4} className="w-full" />
            </div>
          </Carousel>
          <div ref={categoryRef} className="relative bottom-32" />
          <div className="h-2/3 w-5/6 mx-auto mb-8">
            <div>
              {/* heading */}
              <div className="text-left py-6 text-xl md:text-2xl">
                <h1 className=" border-l-4 border-red-600 pl-4 rounded-sm font-medium md:font-normal">
                  Shop By Category
                </h1>
              </div>
            </div>

            {/* category cards */}
            <div>
              <div className="grid md:grid-cols-4 md:gap-x-24 md:gap-y-12 grid-cols-2 gap-4">
                {categoryProducts &&
                  Object.entries(categoryProducts).map((cat) => (
                    <CategoryCard
                      image={
                        cat[1][0].images[0][0]
                          ? cat[1][0].images[0][0]
                          : cat[1][0].images[0]
                      }
                      category={cat[0]}
                    />
                  ))}
              </div>
            </div>
          </div>
          <img src={bannerphoto2} className="w-full"></img>

          {/* Products */}
          <div className="border border-y-2 pt-8 pb-2 bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50">
            <div className="h-2/3 w-5/6 mx-auto">
              <div>
                {/* heading */}
                <div className="text-left pb-6 text-xl md:text-2xl">
                  <h1 className=" border-l-4 border-red-600 pl-4 rounded-sm font-medium md:font-normal">
                    Trending Proteins
                  </h1>
                </div>
              </div>

              {/* Proteins */}
              <div>
                <div className="grid md:grid-cols-4 md:gap-x-12 grid-cols-2 gap-6 md:gap-y-0">
                  {categoryProducts["Whey Protein"] &&
                    categoryProducts["Whey Protein"]
                      .slice(0, 4)
                      .map((prod) => <Product product={prod} />)}
                </div>
              </div>

              <div className="flex justify-center items-center my-4">
                <Link
                  to={`/products?category=${"Whey Protein"}`}
                  className=" rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-slate-800 focus:outline-none focus:ring-4 focus:ring-gray-300"
                >
                  View All Proteins
                </Link>
              </div>
            </div>
          </div>

          <img src={bannerphoto3} className="w-full"></img>

          {/* Products */}
          <div className="border border-y-2 pt-8 pb-2 bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50">
            <div className="h-2/3 w-5/6 mx-auto">
              <div>
                {/* heading */}
                <div className="text-left pb-6 text-xl md:text-2xl">
                  <h1 className=" border-l-4 border-red-600 pl-4 rounded-sm font-medium md:font-normal">
                    Trending Pre Workouts
                  </h1>
                </div>
              </div>
              {/* Pre workout */}
              <div>
                <div className="grid md:grid-cols-4 md:gap-x-12 grid-cols-2 gap-6 md:gap-y-0">
                  {categoryProducts["Pre workout"] &&
                    categoryProducts["Pre workout"]
                      .slice(0, 4)
                      .map((prod) => <Product product={prod} />)}
                </div>
              </div>

              <div className="flex justify-center items-center my-4">
                <Link
                  to={`/products?category=${"Pre workout"}`}
                  className=" rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-slate-800 focus:outline-none focus:ring-4 focus:ring-gray-300"
                >
                  View All Pre Workouts
                </Link>
              </div>
            </div>
          </div>

          <img src={bannerphoto4} className="w-full"></img>

          {/* Products */}
          <div className="border border-y-2 pt-8 pb-2 bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50">
            <div className="h-2/3 w-5/6 mx-auto mb-8">
              <div>
                {/* heading */}
                <div className="text-left pb-6 text-xl md:text-2xl">
                  <h1 className=" border-l-4 border-red-600 pl-4 rounded-sm font-medium md:font-normal">
                    Trending Gainers
                  </h1>
                </div>
              </div>

              {/* Gainer */}
              <div>
                <div className="grid md:grid-cols-4 md:gap-x-12 grid-cols-2 gap-6 md:gap-y-0">
                  {categoryProducts["Gainer"] &&
                    categoryProducts["Gainer"]
                      .slice(0, 4)
                      .map((prod) => <Product product={prod} />)}
                </div>
              </div>

              <div className="flex justify-center items-center my-4">
                <Link
                  to={`/products?category=${"Gainer"}`}
                  className=" rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-slate-800 focus:outline-none focus:ring-4 focus:ring-gray-300"
                >
                  View All Gainers
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
