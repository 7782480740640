import React from "react";

function TermsAndConditions() {
  return (
    <div className="bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50">
      <div className="py-12 md:px-24 px-10">
        <h1 className="text-2xl font-bold mb-8">Terms And Conditions</h1>
        <p className="my-4 text-justify">
          Welcome to Fitnesstrendz.in. Fitnesstrendz.in ('Website'), android &
          IOS applications are online services owned, operated, and managed by
          Square bubbles pvt ltd ('Fitnesstrendz' or 'we' or 'us').{" "}
        </p>
        <p className="my-4 text-justify">
          Please read these Terms and Conditions ("Terms", "Terms and
          Conditions") carefully before using the www.fitnesstrendz.in website
          (the "Service") operated by Fitnesstrendz ("us", "we", or "our").
        </p>
        <p className="my-4 text-justify">
          Your access to and use of the Service are conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users, and others who access or use the Service.
        </p>{" "}
        <p className="my-4 text-justify">
          By accessing or using the Service, you agree to be bound by these
          Terms. If you disagree with any part of the terms, then you may not
          access the Service.
        </p>
        <p className="my-4 text-justify">
          {" "}
          By using the Site, participating in the Rewards or Reviews Program, or
          disclosing any personal information to us:{" "}
        </p>
        <p className="my-4 text-justify">
          (i) you agree that you have read and understand the terms of this
          Agreement,{" "}
        </p>
        <p className="my-4 text-justify">
          (ii) you accept and agree to be bound by the terms of this Agreement,
          and
        </p>{" "}
        <p className="my-4 text-justify">
          (iii) you accept and agree to abide by all laws and regulations
          applicable to the subject matter of this Agreement.{" "}
        </p>
        <p className="my-4 text-justify">
          If you do not agree to the terms of this agreement, do not access or
          otherwise use the site, participate in the rewards program or reviews
          program, or disclose any personal information to us.
        </p>{" "}
        <p className="my-4 text-justify">
          All the information provided to us by you, including sensitive
          personal information, is voluntary.{" "}
        </p>
        <h2 className="text-xl font-medium pt-4">Ownership of Rights</h2>
        <p className="my-4 text-justify">
          {" "}
          Any use of this Website or its contents, including copying or storing
          it or them in whole or part, other than for your own personal,
          non-commercial use, is prohibited without the explicit permission of
          Fitnesstrendz. All information displayed, transmitted, or carried on
          the Website is protected by copyright and other intellectual property
          laws. Copyrights and other intellectual property in respect to some of
          the content on the Website may be owned by third parties. This site is
          designed, updated, and maintained by Fitnesstrendz or its licensors.
          You shall not modify, publish, transmit, transfer, sell, reproduce,
          create derivative work from, distribute, repost, perform, display, or
          in any way commercially exploit any of the content available on the
          Website.
        </p>{" "}
        <h2 className="text-xl font-medium pt-4">Accounts</h2>
        <p className="my-4 text-justify">
          {" "}
          When you create an account with us, you must provide accurate,
          complete, and current information at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service.
        </p>
        <p className="my-4 text-justify">
          {" "}
          You are responsible for safeguarding the password that you use to
          access the Service and for any activities or actions under your
          password, whether your password is with our Service or a third-party
          service.
        </p>
        <p className="my-4 text-justify">
          {" "}
          You agree not to disclose your password to any third party. You must
          notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account.{" "}
        </p>
        <h2 className="text-xl font-medium pt-4">Links To Other Web Sites</h2>
        <p className="my-4 text-justify">
          {" "}
          Our Service may contain links to third-party websites or services that
          are not owned or controlled by Fitnesstrendz.
        </p>
        <p className="my-4 text-justify">
          Fitnesstrendz has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third-party
          websites or services. You further acknowledge and agree that
          Fitnesstrendz shall not be responsible or liable, directly or
          indirectly, for any damage or loss caused or alleged to be caused by
          or in connection with the use of or reliance on any such content,
          goods, or services available on or through any such websites or
          services.
        </p>
        <p className="my-4 text-justify">
          {" "}
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party websites or services that you visit.
        </p>{" "}
        <h2 className="text-xl font-medium pt-4">Pricing Information:</h2>{" "}
        <p className="my-4 text-justify">
          While we strive to provide accurate product and pricing information,
          pricing or typographical errors may occur. We cannot confirm the price
          of a product until after you order. In the event that a product is
          listed at an incorrect price or with incorrect information due to an
          error in pricing or product information, we shall have the right, at
          our sole discretion, to refuse or cancel any orders placed for that
          product, unless the product has already been dispatched. In the event
          that an item is mis-priced, we may, at our sole discretion, either
          contact you for instructions or cancel your order and notify you of
          such cancellation. Unless the product ordered by you has been
          dispatched, your offer will not be deemed accepted, and we will have
          the right to modify the price of the product and contact you for
          further instructions using the e-mail address or the contact number
          provided by you during the time of registration, or cancel the order
          and notify you of such cancellation. In the event we accept your order
          the same shall be debited to your credit/debit card account and duly
          notified to you by email or the contact number, as the case may be,
          that the payment has been processed. The payment may be processed
          prior to the dispatch of the product that you have ordered. If we have
          to cancel the order after we have processed the payment, the said
          amount will be reversed back to your credit/debit card account. We
          strive to provide you with the best value; however, prices and
          availability are subject to change without notice.
        </p>
        <p className="my-4 text-justify">
          {" "}
          Our promotional offers/discounts are not sitewide and are limited to
          selected categories.{" "}
        </p>
        <h2 className="text-xl font-medium pt-4">Quantity Restrictions</h2>
        <p className="my-4 text-justify">
          {" "}
          We reserve the right, at our sole discretion, to limit the quantity of
          items purchased per person, per household, or per order. These
          restrictions may be applicable to orders placed by the same account,
          the same credit/debit card, and also to orders that use the same
          billing and/or shipping address. We will provide notification to the
          customer should such limits be applied. We also reserve the right, at
          our sole discretion, to prohibit sales to any one as we may deem fit.
        </p>{" "}
        <h2 className="text-xl font-medium pt-4">General Terms Of Use</h2>{" "}
        <p className="my-4 text-justify">
          Fitnesstrendz strives to provide the best prices possible on products
          and services to users buying or availing of services from the Website.
          However, Fitnesstrendz does not guarantee that the price will be the
          lowest in the city, region, or geography. Prices and availability are
          subject to change without notice or any consequential liability on
          Fitnesstrendz.
        </p>
        <p className="my-4 text-justify">
          {" "}
          The prices displayed for each item under the promotion will be for the
          respective days of the promotion only and will not be applicable on
          any date prior to or after the date of the promotion. All offers on
          Products shall be for limited stocks and are for a limited period(s).
        </p>
        <p className="my-4 text-justify">
          Fitnesstrendz may at its sole discretion modify, extend, update or
          withdraw the offers on products without prior notice to the Users. In
          such events, the revisions, as the case may be, will be updated on the
          website accordingly.{" "}
        </p>
        <p className="my-4 text-justify">
          A promo code, once used, may not be refunded in case of cancellation
          of an order by the Customer. All products sold on Fitnesstrendz.in are
          genuine. All suppliers providing these products on Fitnesstrendz.in
          are under an agreement to supply only genuine products. We guarantee
          the condition of the item you purchase on Fitnesstrendz.in. However,
          this guarantee is not applicable for manufacturing defects.
        </p>
        <p className="my-4 text-justify">
          Fitnesstrendz may, at any time due to various reasons, including but
          not limited to technical errors, unavailability of stocks or for any
          other reasons whatsoever, cancel the orders placed by Users.
          Fitnesstrendz's decision to cancel an order shall be final, and
          Fitnesstrendz shall not be liable for such cancellations whatsoever.
          Expected delivery time as mentioned on the website may vary from the
          usual time of delivery.
        </p>
        <h2 className="text-xl font-medium pt-4">
          Cancellation by Fitnesstrendz{" "}
        </h2>
        <p className="my-4 text-justify">
          There may be certain orders that Fitnesstrendz is unable to accept and
          must cancel. Fitnesstrendz reserves the right, at its sole discretion,
          to refuse or cancel any order for any reason whatsoever, without
          assigning any reason to the User. The User accepts and undertakes that
          the same is acceptable, and he/she/it will not contest/raise any
          dispute on the same. Situations that may result in the cancellation of
          a User's order include, without limitation, non-availability of the
          product or quantities ordered by the User, inaccuracies, or errors in
          pricing information, or problems identified by Fitnesstrendz's credit
          and fraud avoidance department.
        </p>
        <h2 className="text-xl font-medium pt-4">Accuracy of Content</h2>{" "}
        <p className="my-4 text-justify">
          We have taken all care and precaution to try and provide accurate data
          and information. In the preparation of the content of this Website, in
          particular to ensure that prices quoted are correct at the time of
          publishing and all products have been fairly described. All prices are
          displayed inclusive of GST. We have made every effort to display as
          accurately as possible the colors of our products that appear on the
          Website. However, as the actual colors you see will depend on your
          monitor, we cannot guarantee that your monitor's display of any color
          will accurately reflect the color of the product on delivery. Our
          acceptance of your order will take place upon dispatch of the
          product(s) ordered. Dispatch of all the product(s) ordered may or may
          not happen at the same time.
        </p>{" "}
        <h2 className="text-xl font-medium pt-4">No Healthcare Advice</h2>{" "}
        <p className="my-4 text-justify">
          The products and claims made about specific products on or through the
          Site have not been evaluated by Food Safety & Security Authority of
          India and the United States Food and Drug Administration and are not
          approved to diagnose, treat, cure, or prevent disease.{" "}
        </p>
        <p className="my-4 text-justify">
          The Site is not intended to provide diagnosis, treatment, or medical
          advice. Products, services, information, and other content provided on
          the Site, including information that may be provided on the Site
          directly or by linking to third-party websites, are provided for
          informational purposes only.
        </p>
        <p className="my-4 text-justify">
          {" "}
          Please consult with a physician or other healthcare professional
          regarding any medical or health-related diagnosis or treatment
          options. Information provided on the Site and linked websites,
          including information relating to medical and health conditions,
          treatments, and products, may be provided in summary form. Information
          on the Site, including any product label or packaging, should not be
          considered as a substitute for advice from a healthcare professional.
          Fitnesstrendz does not recommend self-management of health issues.
          Information on the Site is not comprehensive and does not cover all
          diseases, ailments, physical conditions, or their treatment. Contact
          your healthcare professional promptly should you have any
          health-related questions. Never disregard or delay medical advice
          based upon information you may have read on the Site.
        </p>{" "}
        <p className="my-4 text-justify">
          Links to or access from any third-party websites or resources are not
          an endorsement of any information, product, or service. Fitnesstrendz
          is not responsible for the content or performance of any third-party
          websites. Use of any third-party websites is at your own risk.
        </p>{" "}
        <p className="my-4 text-justify">
          You should not use the information or services on the Site to diagnose
          or treat any health issues or for the prescription of any medication
          or other treatment. You should always consult with your healthcare
          professional and read information provided by the product manufacturer
          and any product label or packaging, prior to using any medication,
          nutritional, herbal, or homeopathic product or before beginning any
          exercise or diet program or starting any treatment for a health issue.
          Individuals are different and may react differently to different
          products. You should consult your physician about interactions between
          medications you are taking and nutritional supplements. Comments made
          in any forums on the Site by employees or Site users are strictly
          their own personal views made in their own personal capacity and are
          not claims made by us or do they represent our positions or views.
          Product ratings by any current or previous employees or Site users are
          strictly their own personal views made in their own personal capacity
          and are not intended as a substitute for appropriate medical care or
          advice from a healthcare professional.
        </p>
        <p className="my-4 text-justify">
          Always check the product label or packaging prior to using any
          product. If there are discrepancies, customers should follow the
          information provided on the product label or packaging. You should
          contact the manufacturer directly for clarification as to product
          labeling and packaging details and recommended use.
        </p>{" "}
        <p className="my-4 text-justify">
          We are not liable for any information provided on the Site with regard
          to recommendations regarding supplements for any health purposes. The
          products or claims made about specific nutrients or products have not
          been evaluated by the Food and Drug Administration. Dietary products
          are not intended to treat, prevent, or cure disease. Consult with a
          healthcare professional before starting any diet, supplement, or
          exercise program. We make no guarantee or warranty with respect to any
          products or services sold. We are not responsible for any damages for
          information or services provided even if we have been advised of the
          possibility of damages.
        </p>
        <h2 className="text-xl font-medium pt-4">Promotional Activity:</h2>{" "}
        <p className="my-4 text-justify">
          You may share your Fitnesstrendz.in Reviews as well as your
          Fitnesstrendz.in Rewards Code on a personal blog or any available
          social media accounts (e.g. Facebook, Twitter, and Google+) subject to
          the terms and conditions of those accounts. However, your social media
          profile may not contain an avatar or any other content that could be
          interpreted as being managed by Fitnesstrendz.in. Likewise, your blog,
          website, or social media pages may not resemble the Site or include
          any content that could on its own or in the aggregate be interpreted
          to indicate an affiliation with us, including, but not limited to, the
          use of our trade name, trademark, trade dress, copyrighted material,
          or any similar protected content.
        </p>{" "}
        <p className="my-4 text-justify">
          You may not purchase or use domain names that include or match or
          resemble the word “Fitnesstrendz.in” or certain variations and
          misspellings. We don’t want to confuse the general public about which
          is the official Fitnesstrendz.in website.{" "}
        </p>
        <h2 className="text-xl font-medium pt-4">Termination:</h2>{" "}
        <p className="my-4 text-justify">
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms.
        </p>{" "}
        <p className="my-4 text-justify">
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </p>
        <p className="my-4 text-justify">
          {" "}
          We may terminate or suspend your account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if you breach the Terms. Upon termination, your right to
          use the Service will immediately cease. If you wish to terminate your
          account, you may simply discontinue using the Service.{" "}
        </p>
        <p className="my-4 text-justify">
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </p>
        <h2 className="text-xl font-medium pt-4">Governing Law</h2>{" "}
        <p className="my-4 text-justify">
          These Terms shall be governed and construed in accordance with the
          laws of Delhi, India, without regard to its conflict of law
          provisions. Our failure to enforce any right or provision of these
          Terms will not be considered a waiver of those rights.
        </p>
        <p className="my-4 text-justify">
          {" "}
          If any provision of these Terms is held to be invalid or unenforceable
          by a court, the remaining provisions of these Terms will remain in
          effect. These Terms constitute the entire agreement between us
          regarding our Service, and supersede and replace any prior agreements
          we might have between us regarding the Service.{" "}
        </p>
        <h2 className="text-xl font-medium pt-4">Changes</h2>{" "}
        <p className="my-4 text-justify">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p className="my-4 text-justify">
          {" "}
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </p>{" "}
        <h2 className="text-xl font-medium pt-4">Contact Us</h2>{" "}
        <p className="my-4 text-justify">
          If you have any questions about this Privacy Policy, the practices of
          this site, or your dealings with this site, please contact us.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
