import Breadcrumb from "../../components/admin/Breadcrumb.js";
import {
  clearErrors,
  createProduct,
  deleteProduct,
  updateProduct,
  getAdminProduct,
} from "../../actions/productAction";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NEW_PRODUCT_RESET,
  DELETE_PRODUCT_RESET,
  UPDATE_PRODUCT_RESET,
} from "../../constants/productConstant.js";
import {
  PencilSquareIcon,
  TrashIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { backend_url } from "../../actions/backendUrl";

import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Products = () => {
  const dispatch = useDispatch();

  const { loading, error, products } = useSelector((state) => state.products);

  const newProduct = useSelector((state) => state.newProduct);

  const {
    error: deleteUpdateError,
    isDeleted,
    isUpdated,
  } = useSelector((state) => state.product);

  const [showModal, setShowModal] = useState(false);

  const categories = [
    "Whey Protein",
    "Creatine",
    "Pre workout",
    "Gainer",
    "Omega 3",
    "Fat Burner",
    "Multi vitamin",
    "Isolate Protein",
  ];

  const [mode, setMode] = useState("create");
  const [productId, setProductId] = useState("");
  const [productsByCategory, setProductsByCategory] = useState({});

  const [name, setName] = useState("");
  const [actualPrice, setActualPrice] = useState();
  const [discountedPrice, setDiscountedPrice] = useState();
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [weight, setWeight] = useState("");
  const [flavour, setFlavour] = useState("");
  const [stock, setStock] = useState();
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);

  useEffect(() => {
    let productsByCategoryTemp = {};
    if (products) {
      for (let i = 0; i < products.length; i++) {
        let cat = products[i]["category"];

        if (!(cat in productsByCategoryTemp)) productsByCategoryTemp[cat] = [];

        productsByCategoryTemp[cat].push(products[i]);
      }
    }
    setProductsByCategory(productsByCategoryTemp);
  }, [products]);

  const handleImageChange = (e) => {
    const fileList = e.target.files;

    const newImages = Array.from(fileList);
    const newImagesPreview = Array.from(fileList).map((file) =>
      URL.createObjectURL(file)
    );

    setImages([...images, ...newImages]);
    setImagesPreview([...imagesPreview, ...newImagesPreview]);
  };

  const handleDeleteImage = (e, index) => {
    e.preventDefault();
    const updatedImages = images.filter((_, i) => i !== index);
    const updatedImagesPreview = imagesPreview.filter((_, i) => i !== index);

    setImages(updatedImages);
    setImagesPreview(updatedImagesPreview);
  };

  const deleteProductHandler = (id) => {
    dispatch(deleteProduct(id));
  };

  const createProductSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set(
      "urlname",
      name.replace(/\s+/g, "-").replace("%", "").toLowerCase()
    );
    myForm.set("actualPrice", actualPrice);
    myForm.set("discountedPrice", discountedPrice);
    myForm.set("description", description);
    myForm.set("category", category);
    myForm.set("weight", weight);
    myForm.set("flavour", flavour);
    myForm.set("stock", stock);

    images.forEach((image) => {
      myForm.append("images", image);
    });

    if (mode === "create") {
      dispatch(createProduct(myForm));
    } else if (mode === "edit") {
      dispatch(updateProduct(productId, myForm));
    }
  };
  useEffect(() => {
    dispatch(getAdminProduct());
  }, []);

  useEffect(() => {
    if (newProduct.error) {
      dispatch(clearErrors());
    }

    if (newProduct.success) {
      dispatch({ type: NEW_PRODUCT_RESET });
      window.location.reload();
    }
  }, [newProduct.error, newProduct.success]);

  useEffect(() => {
    if (deleteUpdateError) {
      dispatch(clearErrors());
    }

    if (isDeleted) {
      dispatch({ type: DELETE_PRODUCT_RESET });
      window.location.reload();
    }
  }, [deleteUpdateError, isDeleted]);

  useEffect(() => {
    if (deleteUpdateError) {
      dispatch(clearErrors());
    }

    if (isUpdated) {
      dispatch({ type: UPDATE_PRODUCT_RESET });
      window.location.reload();
    }
  }, [deleteUpdateError, isUpdated]);

  // const [inputFields, setInputFields] = useState([
  //   {
  //     flavour: "",
  //   },
  // ]);

  // const addInputField = () => {
  //   setInputFields([
  //     ...inputFields,
  //     {
  //       flavour: "",
  //     },
  //   ]);
  // };
  // const handleChange = (index, evnt) => {
  //   const { name, value } = evnt.target;
  //   const list = [...inputFields];
  //   list[index][name] = value;
  //   setInputFields(list);

  //   let flav = [];
  //   list.map((field) => flav.push(field.flavour));

  //   setFlavour(flav);
  // };

  const addProduct = () => {
    setMode("create");

    setName("");
    setActualPrice();
    setDiscountedPrice();
    setDescription("");
    setEditorState(EditorState.createEmpty());
    setCategory("");
    setWeight("");
    setFlavour("");
    setStock();
    setImages([]);
    setImagesPreview([]);

    setShowModal(true);
  };

  const closeModal = () => {
    setMode("create");
    setShowModal(false);
  };

  const editProduct = (product) => {
    setMode("edit");
    setProductId(product._id);

    setName(product.name);
    setActualPrice(product.actualPrice);
    setDiscountedPrice(product.discountedPrice);
    setDescription(product.description);
    if (product.description[0] === "<") {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(product.description)
          )
        )
      );
    } else {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromText(product.description)
        )
      );
    }
    setCategory(product.category);
    setWeight(product.weight);
    setFlavour(product.flavour);
    setStock(product.stock);
    setImages(product.images);

    let temp = [];

    for (let i = 0; i < product.images.length; i++) {
      temp.push(backend_url + `/uploads/${product.images[i]}`);
    }
    setImagesPreview(temp);

    setShowModal(true);
  };

  const addSimilarProduct = (product) => {
    setMode("create");

    setName(product.name);
    setActualPrice();
    setDiscountedPrice();
    setDescription(product.description);
    setCategory(product.category);
    setWeight(product.weight);
    setFlavour(product.flavour);
    setStock();
    setImages([]);
    setImagesPreview([]);

    setShowModal(true);
  };

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <>
      <Breadcrumb pageName="Products" />
      <button
        className="bg-gray-500 text-white hover:bg-gray-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-6 ease-linear transition-all duration-300"
        type="button"
        onClick={() => addProduct()}
      >
        + Add Product
      </button>

      {showModal ? (
        <div
          className="py-16 bg-gray-700/50 transition duration-150 ease-in-out z-20 fixed top-32 right-0 bottom-0 left-0 overflow-auto"
          id="modal"
        >
          <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
            <form
              className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400"
              onSubmit={createProductSubmitHandler}
            >
              <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">
                Enter Product Details
              </h1>
              <label
                for="name"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Product Name
              </label>
              <input
                onChange={(e) => setName(e.target.value)}
                type="text"
                id="name"
                value={name}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="Product Name"
              />
              <label
                for="actualPrice"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Actual Price
              </label>
              <input
                onChange={(e) => setActualPrice(e.target.value)}
                type="number"
                id="actualPrice"
                value={actualPrice}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="Actual Price"
              />
              <label
                for="discountedPrice"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Discounted Price
              </label>
              <input
                onChange={(e) => setDiscountedPrice(e.target.value)}
                type="number"
                id="discountedPrice"
                value={discountedPrice}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="Discounted Price"
              />
              <label
                for="description"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Description
              </label>
              {/* <textarea
                onChange={(e) => setDescription(e.target.value)}
                type="text"
                id="description"
                value={description}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-20 pt-2 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="Description"
              /> */}
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                editorClassName="pl-3 pb-2"
                wrapperClassName="my-2 border-gray-300 rounded border"
                placeholder="Description"
              />
              <label
                for="category"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Category
              </label>
              <select
                id="category"
                onChange={(e) => setCategory(e.target.value)}
                value={category}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
              >
                <option value="">Choose Category</option>
                {categories.map((cate) => (
                  <option key={cate} value={cate}>
                    {cate}
                  </option>
                ))}
              </select>
              <label
                for="weight"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Weight
              </label>
              <input
                onChange={(e) => setWeight(e.target.value)}
                type="text"
                id="weight"
                value={weight}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="Weight"
              />
              <label
                for="flavour"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Flavour
              </label>
              <input
                onChange={(e) => setFlavour(e.target.value)}
                type="text"
                id="flavour"
                value={flavour}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="Flavour"
              />
              {/* <label
                for="flavour"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Flavour
              </label>
              {inputFields.map((data, index) => {
                return (
                  <input
                    type="text"
                    onChange={(evnt) => handleChange(index, evnt)}
                    name="flavour"
                    className="mb-3 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder="Flavour"
                  />
                );
              })} */}
              {/* <div>
                <button
                  type="button"
                  className="bg-gray-600 rounded text-white px-3 py-2 text-xs mb-5"
                  onClick={addInputField}
                >
                  + Add Flavour
                </button>
              </div> */}
              <label
                for="stock"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Stock
              </label>
              <input
                onChange={(e) => setStock(e.target.value)}
                type="number"
                id="stock"
                value={stock}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="Stock"
              />
              <label
                for="Images"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Images
              </label>
              <div>
                <input
                  type="file"
                  onChange={handleImageChange}
                  name="images"
                  accept="image/*"
                  multiple
                />
                <div className="mb-5 flex flex-wrap">
                  {imagesPreview.map((image, index) => (
                    <div key={index} className="relative">
                      <img
                        src={image}
                        alt={`Image ${index}`}
                        className="w-20 h-20 pt-2 pr-2"
                      />
                      <button
                        onClick={(e) => handleDeleteImage(e, index)}
                        className="absolute top-2 right-2"
                      >
                        <XMarkIcon className="h-5 stroke-2" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-start w-full">
                <button
                  className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition duration-150 ease-in-out hover:bg-gray-600 bg-gray-700 rounded text-white px-8 py-2 text-sm"
                  type="submit"
                >
                  Submit
                </button>
                <button
                  className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>
              </div>
              <button
                className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                onClick={() => closeModal()}
                aria-label="close modal"
                role="button"
              >
                <XMarkIcon className="h-5 stroke-[2.5]" />
              </button>
            </form>
          </div>
        </div>
      ) : null}

      <div className="flex flex-col gap-10">
        {productsByCategory &&
          Object.entries(productsByCategory).map((product) => (
            <div className="rounded-sm border border-stroke bg-white p-2 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="max-w-full overflow-x-auto">
                <h1 className="pl-2 pb-2 font-semibold text-lg">
                  {product[0]}
                </h1>
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-2 text-left dark:bg-meta-4">
                      <th className="min-w-[220px] py-4 px-3 font-medium text-blackadmin dark:text-white xl:pl-11">
                        Product Name
                      </th>
                      <th className="min-w-[150px] py-4 px-3 font-medium text-blackadmin dark:text-white">
                        Flavour
                      </th>
                      <th className="min-w-[150px] py-4 px-3 font-medium text-blackadmin dark:text-white">
                        Weight
                      </th>
                      <th className="min-w-[120px] py-4 font-medium text-blackadmin dark:text-white">
                        Price
                      </th>
                      <th className="min-w-[120px] py-4 px-3 font-medium text-blackadmin dark:text-white">
                        Stock
                      </th>
                      <th className="py-4 px-3 font-medium text-blackadmin dark:text-white">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {product[1].map((prod) => (
                    <tbody>
                      <tr>
                        <td className="border-b border-[#eee] py-5 px-3 pl-9 dark:border-strokedark xl:pl-11">
                          <h5 className="font-medium text-blackadmin dark:text-white">
                            {prod.name}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-3 dark:border-strokedark">
                          <p className="text-blackadmin dark:text-white">
                            {prod.flavour}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-3 dark:border-strokedark">
                          <p className="text-blackadmin dark:text-white">
                            {prod.weight}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-5  dark:border-strokedark">
                          <div className="flex">
                            <span className="font-medium">
                              ₹{prod.discountedPrice}
                            </span>
                            <span className="ml-1 line-through text-xs self-center">
                              ₹{prod.actualPrice}
                            </span>
                          </div>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-3 dark:border-strokedark">
                          <p className="text-blackadmin dark:text-white">
                            {prod.stock}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-3 dark:border-strokedark">
                          <div className="flex items-center space-x-2">
                            <button className="hover:text-primary">
                              <PlusCircleIcon
                                className="h-5 stroke-[1.5]"
                                onClick={() => addSimilarProduct(prod)}
                              />
                            </button>
                            <button
                              className="hover:text-primary"
                              onClick={() => editProduct(prod)}
                            >
                              <PencilSquareIcon className="h-5 stroke-[1.5]" />
                            </button>
                            <button
                              className="hover:text-primary"
                              onClick={() =>
                                window.confirm(
                                  "Are you sure you want to delete this item?"
                                )
                                  ? deleteProductHandler(prod._id)
                                  : ""
                              }
                            >
                              <TrashIcon className="h-5 stroke-[1.5]" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Products;
