import Breadcrumb from "../../components/admin/Breadcrumb.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../actions/userAction.js";
const Users = () => {
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.allUsers);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  return (
    <>
      <Breadcrumb pageName="Users" />

      <div className="flex flex-col gap-10">
        <table className="w-full table-auto my-4">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="min-w-[150px] py-4 px-3 font-medium text-blackadmin dark:text-white">
                Name
              </th>
              <th className="min-w-[150px] py-4 px-3 font-medium text-blackadmin dark:text-white">
                Email
              </th>
              <th className="py-4 px-3 font-medium text-blackadmin dark:text-white">
                Role
              </th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => (
                <tr>
                  <td className="border-b border-[#eee] py-5 px-3 dark:border-strokedark">
                    <p className="text-blackadmin dark:text-white">
                      {user.name}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-3 dark:border-strokedark">
                    <p className="text-blackadmin dark:text-white">
                      {user.email}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-3 dark:border-strokedark">
                    <p className="text-blackadmin dark:text-white">
                      {user.role}
                    </p>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Users;
