import React, { useEffect } from "react";
import Product from "../components/Product";
import { getProduct } from "../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";

const AllProducts = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const location = new URLSearchParams(search);

  const { loading, products } = useSelector((state) => state.products);

  useEffect(() => {
    if (location.get("keyword")) dispatch(getProduct(location.get("keyword")));
    else if (location.get("category"))
      dispatch(getProduct("", location.get("category")));
    else dispatch(getProduct());
  }, [dispatch, search]);

  return (
    <div className="bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50">
      {loading ? (
        <Loader />
      ) : (
        <div className="h-2/3 w-5/6 mx-auto pb-8">
          <div>
            {/* heading */}
            <div className="text-left py-6 text-2xl">
              <h1 className=" border-l-4 border-red-600 pl-4 rounded-sm">
                {location.get("category")
                  ? location.get("category") + "s"
                  : "All Products"}
              </h1>
            </div>
          </div>

          {/* Proteins */}
          <div>
            <div className="grid md:grid-cols-4 md:gap-x-12 grid-cols-2 gap-6 md:gap-y-10">
              {products && Object.values(products).length > 0 ? (
                Object.values(products).map((prod) => (
                  <Product product={prod} />
                ))
              ) : (
                <div className="h-60 w-screen mt-24">
                  <h2 className="text-2xl">No Products Found...</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProducts;
