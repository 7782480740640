import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckoutSteps from "../components/CheckoutSteps";
import { clearErrors, login, register } from "../actions/userAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IdentificationIcon } from "@heroicons/react/24/outline";

function CheckoutLogin() {
  const dispatch = useDispatch();

  const [loginState, setLoginState] = useState("login");

  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const loginSubmit = (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    dispatch(login(email, password));
  };

  const registerSubmit = (e) => {
    e.preventDefault();
    const name = document.getElementById("regname").value;
    const email = document.getElementById("regemail").value;
    const password = document.getElementById("regpassword").value;

    dispatch(register(name, email, password));
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
      if (error == "Invalid email or password") {
        toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (error == "Duplicate email Entered") {
        toast.error("Email ID already exists.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        error ==
        "User validation failed: password: Password should be greater than 8 characters"
      ) {
        toast.error("Password should be greater than 8 characters", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    if (isAuthenticated) {
      window.location.pathname = "/shipping";
    }
  }, [dispatch, error, isAuthenticated]);
  return (
    <>
      <CheckoutSteps activeStep={0} />
      <div className="flex flex-col text-center items-center my-10">
        <ToastContainer />
        {loginState == "login" ? (
          <div className="flex items-center justify-center p-6 sm:p-10 md:w-1/3 shadow-lg hover:shadow-xl">
            <div className="w-full">
              <div className="flex justify-center items-center mb-4">
                <IdentificationIcon className="mr-1 w-10 h-10 text-red-600" />
                <h2 className="text-2xl font-semibold text-gray-600">
                  Sign In
                </h2>
              </div>
              <form onSubmit={loginSubmit}>
                <div className="mt-4">
                  <label className="block text-sm">Email</label>
                  <input
                    type="email"
                    id="email"
                    className="w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
                    placeholder="Email Address"
                    required
                  />
                </div>
                <div>
                  <label className="block mt-4 text-sm">Password</label>
                  <input
                    id="password"
                    className="w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
                    placeholder="Password"
                    type="password"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-gray"
                  href="#"
                >
                  Sign In
                </button>
              </form>
              <div className="mt-4 text-center">
                <p className="text-sm">
                  Don't have an account yet?
                  <span
                    className="text-red-600 hover:underline cursor-pointer"
                    onClick={() => setLoginState("signup")}
                  >
                    {" "}
                    Sign Up.
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center p-6 sm:p-10 md:w-1/3 shadow-lg hover:shadow-xl">
            <div className="w-full">
              <div className="flex justify-center items-center mb-4">
                <IdentificationIcon className="mr-1 w-10 h-10 text-red-600" />
                <h2 className="text-2xl font-semibold text-gray-600">
                  Sign Up
                </h2>
              </div>
              <form onSubmit={registerSubmit}>
                <div>
                  <label className="block text-sm">Name</label>
                  <input
                    type="text"
                    id="regname"
                    className="w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
                    placeholder="Name"
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-sm">Email</label>
                  <input
                    type="email"
                    id="regemail"
                    className="w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
                    placeholder="Email Address"
                  />
                </div>
                <div>
                  <label className="block mt-4 text-sm">Password</label>
                  <input
                    id="regpassword"
                    className="w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
                    placeholder="Password"
                    type="password"
                  />
                </div>
                <button
                  type="submit"
                  className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-gray"
                  href="#"
                >
                  Sign up
                </button>
              </form>
              <div className="mt-4 text-center">
                <p className="text-sm">
                  Already have an account?{" "}
                  <span
                    className="text-red-600 hover:underline cursor-pointer"
                    onClick={() => setLoginState("login")}
                  >
                    Sign In.
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CheckoutLogin;
