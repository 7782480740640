import React from "react";

function ReturnAndRefunds() {
  return (
    <div className="bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50">
      <div className="py-12 md:px-24 px-10 ">
        <h1 className="text-2xl font-bold pb-8">Return And Refunds</h1>
        <p className="mb-4 text-justify">
          We strongly believe in our products and want you to be fully satisfied
          with the purchases you make from us. Each product goes through
          rigorous trials and is personally taste-tested by us until it’s just
          right.
        </p>{" "}
        <h2 className="text-xl font-medium pt-4">10 Day Returns</h2>
        <p className="my-4 text-justify">
          We will fully refund any unopened/unused product for a period of 10
          days after shipping date, product must be in its original packaging.
          Unless you are returning a defective product, you will be responsible
          for return freight charges.
        </p>{" "}
        <p className="my-4 text-justify">
          Once the product is received, we’ll process the return for you in
          approx. 24-48 hours. See how to return a product instructions.
        </p>{" "}
        <h2 className="text-xl font-medium pt-4">Defective Products</h2>{" "}
        <p className="my-4 text-justify">
          If there’s a problem with your product please contact our Customer
          Support Team immediately. We’ll email you a return label to put on
          your box and return to us. Once the package is in transit we’ll go
          ahead and ship you a replacement, issue you a store credit or give you
          a full refund of the product price. ** In Case of Damaged Inner Seal,
          Return/Refund will only be granted on the basis of Unboxing video
          while openeing the package.
        </p>
        <h2 className="text-xl font-medium pt-4">Conditions for Returns </h2>
        <p className="my-4 text-justify">
          In order for the Goods to be eligible for a return, please make sure
          that:
        </p>{" "}
        <p className="my-4 text-justify">
          The Goods were purchased in the last 7 days
        </p>
        <p className="my-4 text-justify">
          The Goods are in the original packaging
        </p>
        <p className="my-4 text-justify">
          We reserve the right to refuse returns of any merchandise that does
          not meet the above return conditions in our sole discretion.
        </p>
      </div>
    </div>
  );
}

export default ReturnAndRefunds;
