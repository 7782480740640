import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  SAVE_SHIPPING_INFO,
} from "../constants/cartConstant";
import axios from "axios";
import { backend_url } from "./backendUrl";

export const addItemsToCart = (urlname, index, quantity) => async (dispatch, getState) => {
const { data } = await axios.get(backend_url + `/api/v1/product/${urlname}`);

  dispatch({
    type: ADD_TO_CART,
    payload: {
      product: data.product._id,
      name: data.product.name,
      weight: data.product.weight[index],
      flavour: data.product.flavour[index],
      price: data.product.discountedPrice[index],
      image: data.product.images[index][0],
      stock: data.product.stock[index],
      urlname,
      index,
      quantity,
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// REMOVE FROM CART
export const removeItemsFromCart = (product, weight, flavour) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: {
      product: product,
      weight: weight,
      flavour: flavour,
    },
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// SAVE SHIPPING INFO
export const saveShippingInfo = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data,
  });

  localStorage.setItem("shippingInfo", JSON.stringify(data));
};