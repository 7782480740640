import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckoutSteps from "../components/CheckoutSteps";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { backend_url } from "../actions/backendUrl";
import axios from "axios";

function ConfirmOrder() {
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);

  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );

  const shippingCharges = 0;

  const tax = subtotal * 0;

  const totalPrice = subtotal + tax + shippingCharges;

  const address = `${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.pinCode}, ${shippingInfo.country}`;

  const proceedToPayment = async (e) => {
    e.preventDefault();

    const data = {
      subtotal,
      shippingCharges,
      tax,
      totalPrice,
    };

    sessionStorage.setItem("orderInfo", JSON.stringify(data));

    const APIdata = {
      // amount: 1,
      amount: subtotal,
      number: shippingInfo.phoneNo,
      MUID: "MUID" + Date.now(),
      transactionId: "T" + Date.now(),
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      backend_url + "/api/v1/payment",
      APIdata,
      config
    );

    window.location.href = response.data;
  };

  return (
    <>
      <CheckoutSteps activeStep={2} />
      <div className="flex flex-col md:flex-row">
        <div className="md:m-12 md:px-16 p-8 border-r-2 md:w-1/2">
          <div>
            <h2 className="text-2xl font-semibold">Shipping Info</h2>
            <div className="mt-4">
              <div className="flex text-lg">
                <p className="mr-2 font-medium">Name:</p>
                <span>{user && user.name}</span>
              </div>
              <div className="flex text-lg">
                <p className="mr-2 font-medium">Phone:</p>
                <span>{shippingInfo.phoneNo}</span>
              </div>
              <div className="flex text-lg">
                <p className="mr-2 font-medium">Address:</p>
                <span>{address}</span>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <h2 className="text-2xl font-semibold">Your Cart Items:</h2>
            <div className="mt-6">
              {cartItems &&
                cartItems.map((item) => (
                  <div className="flex my-4 md:my-0" key={item.product}>
                    <img
                      src={backend_url + `/uploads/${item.image}`}
                      alt={item.name}
                      className="w-10 md:w-14"
                    />
                    <Link
                      to={`/product/${item.urlname}`}
                      className="my-auto md:text-lg font-medium mx-3 md:mx-8"
                    >
                      {item.name}
                    </Link>
                    <span className="text-sm md:text-base my-auto ml-auto">
                      {item.quantity} X ₹{item.price}&nbsp;=&nbsp;
                      <b>₹{item.price * item.quantity}</b>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div>
          <div className="md:my-12 md:mx-16 pb-8 w-80 mx-auto">
            <h2 className="text-2xl font-semibold text-center">
              Order Summary
            </h2>
            <div className="text-lg mt-4">
              <hr className="my-4" />
              <div className="flex my-2 justify-between">
                <p className="text-gray-700">Subtotal</p>
                <p className="text-gray-700">₹ {subtotal.toFixed(2)}</p>
              </div>
              <div className="flex my-2 justify-between">
                <p className="text-gray-700">Shipping</p>
                <p className="text-gray-700">₹ 0.00</p>
              </div>
              <div className="flex my-2 justify-between">
                <p className="text-gray-700">Tax</p>
                <p className="text-gray-700">₹ 0.00</p>
              </div>
              <hr className="my-4" />
              <div className="flex justify-between">
                <p className="text-lg font-bold">Total</p>
                <div className="grid">
                  <p className="mb-1 text-lg font-bold">
                    ₹ {subtotal.toFixed(2)}
                  </p>
                  <p className="text-sm text-gray-700 justify-self-end">
                    including GST
                  </p>
                </div>
              </div>
              <button
                onClick={proceedToPayment}
                className="mt-6 w-full rounded-md bg-red-500 py-1.5 font-medium text-red-50 hover:bg-red-600"
              >
                Proceed To Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmOrder;
