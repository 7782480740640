import Breadcrumb from "../../components/admin/Breadcrumb.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrders,
  updateOrder,
  clearErrors,
} from "../../actions/orderAction";
import { getAllUsers } from "../../actions/userAction.js";

const Orders = () => {
  const dispatch = useDispatch();

  const { error, orders } = useSelector((state) => state.allOrders);
  const { users } = useSelector((state) => state.allUsers);
  const { isUpdated } = useSelector((state) => state.order);

  const [orderStatusDict, setOrderStatusDict] = useState({});

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

    dispatch(getAllOrders());
    dispatch(getAllUsers());
  }, [dispatch, error]);

  useEffect(() => {
    if (isUpdated) {
      window.location.reload();
    }
  }, [isUpdated]);

  useEffect(() => {
    let orderStatusTemp = {};
    if (orders) {
      for (let i = 0; i < orders.length; i++) {
        let status = orders[i]["orderStatus"];

        if (!(status in orderStatusTemp)) orderStatusTemp[status] = [];

        orderStatusTemp[status].push(orders[i]);
      }
    }
    setOrderStatusDict(orderStatusTemp);
  }, [orders]);

  const getUserDetails = (userId) => {
    if (users) {
      for (var i in users) {
        if (users[i]._id == userId) {
          return users[i];
        }
      }
      return null;
    }
  };

  const handleStatusChange = (status, id) => {
    const myForm = new FormData();

    myForm.set("status", status);

    dispatch(updateOrder(id, myForm));
  };

  return (
    <>
      <Breadcrumb pageName="Orders" />

      <div className="flex flex-col gap-10 pt-4">
        <div className="rounded-sm border border-stroke bg-white p-2 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="max-w-full overflow-x-auto">
            <h1 className="pl-4 font-semibold text-lg text-red-600">
              New Orders
            </h1>

            {orderStatusDict["Processing"] &&
              orderStatusDict["Processing"].reverse().map((order) => (
                <div className="rounded-sm border border-stroke m-4 p-4">
                  <div className="">
                    <div className="md:flex justify-between">
                      <p>
                        <span className="font-semibold">Order Date: </span>
                        {new Date(order.createdAt).toLocaleDateString()}
                      </p>
                      <p>
                        <span className="font-semibold">Address: </span>
                        {order.shippingInfo.address}, {order.shippingInfo.city},
                        {order.shippingInfo.state}, {order.shippingInfo.pinCode}
                        ,{order.shippingInfo.country}
                      </p>
                    </div>
                    <div className="md:flex justify-between my-2">
                      <p className="max-w-4xl">
                        <span className="font-semibold">Payment ID: </span>
                        {order.paymentInfo.id}
                      </p>
                      <p>
                        <span className="font-semibold">Payment Status: </span>
                        {order.paymentInfo.status.toUpperCase()}
                      </p>
                    </div>
                    <div className="md:flex justify-between my-2">
                      <p className="max-w-4xl">
                        <span className="font-semibold">Items: </span>
                        {order.orderItems
                          .map((item) => `${item.name} X ${item.quantity}`)
                          .join(", ")}
                      </p>
                      <p>
                        <span className="font-semibold">Total: </span>₹
                        {order.totalPrice}
                      </p>
                    </div>
                    <div className="md:flex justify-between my-2">
                      <p className="max-w-4xl">
                        <span className="font-semibold">Customer Name: </span>
                        {getUserDetails(order.user).name}
                      </p>
                      <p>
                        <span className="font-semibold">
                          Customer Email ID:{" "}
                        </span>
                        {getUserDetails(order.user).email}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <button
                      className="my-2 rounded-md bg-gray-500 py-1.5 font-medium text-red-50 w-60 hover:bg-gray-600"
                      onClick={() =>
                        window.confirm(
                          "Are you sure you want to move this item to Dispatched?"
                        )
                          ? handleStatusChange("Dispatched", order._id)
                          : ""
                      }
                    >
                      Move to Dispatched
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="rounded-sm border border-stroke bg-white p-2 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="max-w-full overflow-x-auto">
            <h1 className="pl-4 font-semibold text-lg text-yellow-600">
              Dispatched
            </h1>
            {orderStatusDict["Dispatched"] &&
              orderStatusDict["Dispatched"].reverse().map((order) => (
                <div className="rounded-sm border border-stroke m-4 p-4">
                  <div className="">
                    <div className="md:flex justify-between">
                      <p>
                        <span className="font-semibold">Order Date: </span>
                        {new Date(order.createdAt).toLocaleDateString()}
                      </p>
                      <p>
                        <span className="font-semibold">Address: </span>
                        {order.shippingInfo.address}, {order.shippingInfo.city},
                        {order.shippingInfo.state}, {order.shippingInfo.pinCode}
                        ,{order.shippingInfo.country}
                      </p>
                    </div>
                    <div className="md:flex justify-between my-2">
                      <p className="max-w-4xl">
                        <span className="font-semibold">Payment ID: </span>
                        {order.paymentInfo.id}
                      </p>
                      <p>
                        <span className="font-semibold">Payment Status: </span>
                        {order.paymentInfo.status.toUpperCase()}
                      </p>
                    </div>
                    <div className="md:flex justify-between my-2">
                      <p className="max-w-4xl">
                        <span className="font-semibold">Items: </span>
                        {order.orderItems
                          .map((item) => `${item.name} X ${item.quantity}`)
                          .join(", ")}
                      </p>
                      <p>
                        <span className="font-semibold">Total: </span>₹
                        {order.totalPrice}
                      </p>
                    </div>
                    <div className="md:flex justify-between my-2">
                      <p className="max-w-4xl">
                        <span className="font-semibold">Customer Name: </span>
                        {getUserDetails(order.user).name}
                      </p>
                      <p>
                        <span className="font-semibold">
                          Customer Email ID:{" "}
                        </span>
                        {getUserDetails(order.user).email}
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      <button
                        className="my-2 rounded-md bg-gray-500 py-1.5 font-medium text-red-50 w-54 hover:bg-gray-600"
                        onClick={() =>
                          window.confirm(
                            "Are you sure you want to move this item to Delivered?"
                          )
                            ? handleStatusChange("Delivered", order._id)
                            : ""
                        }
                      >
                        Move to Delivered
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="rounded-sm border border-stroke bg-white p-2 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="max-w-full overflow-x-auto">
            <h1 className="pl-4 font-semibold text-lg text-green-600">
              Delivered{" "}
            </h1>

            {orderStatusDict["Delivered"] &&
              orderStatusDict["Delivered"].map((order) => (
                <div className="rounded-sm border border-stroke m-4 p-4">
                  <div className="">
                    <div className="md:flex justify-between">
                      <p>
                        <span className="font-semibold">Order Date: </span>
                        {new Date(order.createdAt).toLocaleDateString()}
                      </p>
                      <p>
                        <span className="font-semibold">Address: </span>
                        {order.shippingInfo.address}, {order.shippingInfo.city},
                        {order.shippingInfo.state}, {order.shippingInfo.pinCode}
                        ,{order.shippingInfo.country}
                      </p>
                    </div>
                    <div className="md:flex justify-between my-2">
                      <p className="max-w-4xl">
                        <span className="font-semibold">Payment ID: </span>
                        {order.paymentInfo.id}
                      </p>
                      <p>
                        <span className="font-semibold">Payment Status: </span>
                        {order.paymentInfo.status.toUpperCase()}
                      </p>
                    </div>
                    <div className="md:flex justify-between my-2">
                      <p className="max-w-4xl">
                        <span className="font-semibold">Items: </span>
                        {order.orderItems
                          .map((item) => `${item.name} X ${item.quantity}`)
                          .join(", ")}
                      </p>
                      <p>
                        <span className="font-semibold">Total: </span>₹
                        {order.totalPrice}
                      </p>
                    </div>
                    <div className="md:flex justify-between my-2">
                      <p className="max-w-4xl">
                        <span className="font-semibold">Customer Name: </span>
                        {users && getUserDetails(order.user).name}
                      </p>
                      <p>
                        <span className="font-semibold">
                          Customer Email ID:{" "}
                        </span>
                        {users && getUserDetails(order.user).email}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
