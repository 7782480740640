import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, register } from "../actions/userAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IdentificationIcon } from "@heroicons/react/24/outline";

function Register() {
  const dispatch = useDispatch();

  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const registerSubmit = (e) => {
    e.preventDefault();
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    dispatch(register(name, email, password));
  };

  useEffect(() => {
    if (error) {
      if (error) {
        dispatch(clearErrors());
        if (error == "Duplicate email Entered") {
          toast.error("Email ID already exists.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        else if(error == "User validation failed: password: Password should be greater than 8 characters"){
          toast.error("Password should be greater than 8 characters", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }

    if (isAuthenticated) {
      window.location.pathname = "/";
    }
  }, [dispatch, error, isAuthenticated]);

  return (
    <div>
      <ToastContainer />
      <div className="flex items-center py-12 bg-gray-50">
        <div className="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl">
          <div className="flex flex-col md:flex-row">
            <div className="h-32 md:h-auto md:w-1/2">
              <img
                className="object-cover w-full h-full"
                src="https://source.unsplash.com/user/erondu/1600x900"
                alt="img"
              />
            </div>
            <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
              <div className="w-full">
                <div className="flex justify-center">
                  <IdentificationIcon className="w-16 h-16 text-red-600" />
                </div>
                <h1 className="mb-4 text-2xl font-bold text-center text-gray-700">
                  Sign up
                </h1>
                <form id="signupform" onSubmit={registerSubmit}>
                  <div>
                    <label className="block text-sm">Name</label>
                    <input
                      type="text"
                      id="name"
                      className="w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
                      placeholder="Name"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm">Email</label>
                    <input
                      type="email"
                      id="email"
                      className="w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
                      placeholder="Email Address"
                    />
                  </div>
                  <div>
                    <label className="block mt-4 text-sm">Password</label>
                    <input
                      id="password"
                      className="w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-600"
                      placeholder="Password"
                      type="password"
                    />
                  </div>
                  <button
                    type="submit"
                    className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-gray"
                    href="#"
                  >
                    Sign up
                  </button>
                </form>
                <div className="mt-4 text-center">
                  <p className="text-sm">
                    Already have an account?{" "}
                    <a href="/login" className="text-red-600 hover:underline">
                      {" "}
                      Sign In.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
