import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userAction";
import Address from "../components/Address";
import { clearErrors, myOrders } from "../actions/orderAction";

function Profile() {
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);
  const { error, orders } = useSelector((state) => state.myOrders);

  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

    dispatch(myOrders());
  }, []);

  console.log(orders);

  return (
    <>
      {user && (
        <div className="flex flex-col text-center items-center my-12 ">
          <h2 className="text-2xl font-semibold">My Profile</h2>
          <div className="mt-6 flex flex-col justify-center items-center">
            <div className="flex text-lg">
              <p className="mr-2 font-medium">Name:</p>
              <span>{user.name}</span>
            </div>
            <div className="flex text-lg">
              <p className="mr-2 font-medium">Email:</p>
              <span>{user.email}</span>
            </div>
          </div>
          <div className="w-full">
            <div className="w-full md:w-5/6 mt-8 mb-4 mx-auto">
              <div className="rounded-sm border border-gray-300 bg-white p-2 ">
                <h1 className="text-xl font-semibold my-2">My Orders</h1>
                {orders && orders.length > 0 ? (
                  orders.reverse().map((order) => (
                    <div className="rounded-sm border border-stroke m-4 p-4">
                      <div className="">
                        <div className="md:flex justify-between">
                          <p>
                            <span className="font-semibold">Order Date: </span>
                            {new Date(order.createdAt).toLocaleDateString()}
                          </p>
                          <p>
                            <span className="font-semibold">Address: </span>
                            {order.shippingInfo.address},{" "}
                            {order.shippingInfo.city}
                          </p>
                          <p>
                            <span className="font-semibold">Status: </span>
                            {order.orderStatus == "Delivered" ? (
                              <span className="text-green-600">
                                {order.orderStatus}
                              </span>
                            ) : (
                              <span className="text-red-600">
                                {order.orderStatus}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="md:flex justify-between my-2">
                          <p className="max-w-4xl">
                            <span className="font-semibold">Items: </span>
                            {order.orderItems
                              .map((item) => `${item.name} X ${item.quantity}`)
                              .join(", ")}
                          </p>
                          <p>
                            <span className="font-semibold">Total: </span>₹
                            {order.totalPrice}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  // })
                  <div className="mt-4 mb-2">
                    <h1>
                      No Orders Found.{" "}
                      <a
                        href="/products"
                        className="text-red-600 hover:underline"
                      >
                        Shop Now!
                      </a>
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Address />
          <button
            onClick={() => logoutUser()}
            className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition duration-150 ease-in-out hover:bg-gray-600 bg-gray-700 rounded text-white px-8 py-3 text-sm"
          >
            Logout
          </button>
        </div>
      )}
    </>
  );
}

export default Profile;
