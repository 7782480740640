import React, { useState } from "react";

const Certificates = () => {
  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const images = importAll(
    require.context("../static/certificates", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <>
      <div className="grid md:grid-cols-2 gap-4 m-4 bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50">
        {Object.values(images).map((image, index) => (
          <img key={index} src={image} alt={`image-${index}`} />
        ))}
      </div>
    </>
  );
};

export default Certificates;
