import React from "react";

function ShippingPolicy() {
  return (
    <div className="bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50">
      <div className="py-12 md:px-24 px-10 ">
        <h1 className="text-2xl font-bold pb-8">Shipping Policy</h1>
        <h2 className="text-xl font-medium">
          How do I know if my order has shipped?
        </h2>
        <p className="my-4 text-justify">
          {" "}
          Once you have completed your order, you will receive a confirmation on
          registered number and/or Email address used at checkout. Once your
          package has been picked up by our contracted Logistics Partner
          (FedEx/Delhivery/gray dart/XpressBees) you will receive tracking
          information via the email address used at checkout.{" "}
        </p>
        <h2 className="text-xl font-medium pt-4">
          How soon can I expect my order to be delivered?
        </h2>
        <p className="my-4 text-justify">
          {" "}
          We ship to most pincodes in India. We do not ship outside of India.
          Shipping is free for all orders. Delivery takes about 3-5 business
          days for all orders.
        </p>
        <h2 className="text-xl font-medium pt-4">
          How long do you take to dispatch an order?
        </h2>
        <p className="my-4 text-justify">
          {" "}
          DISPATCH means completing the order and handing it over to our
          Logistics Partner (FedEx/Delhivery/gray dart/XpressBees).All orders
          placed before 11 AM will be dispatched same day.{" "}
        </p>
        <h2 className="text-xl font-medium pt-4">
          What happens if the package i receive is damaged?
        </h2>
        <p className="my-4 text-justify">
          {" "}
          Please do not accept any package that is damaged. Notify us
          immediately. We will either resend the item(s) you have ordered or we
          shall refund the whole amount you paid to us. Refunds applicable only
          if reported within 10 days of delivery.
        </p>
        <h2 className="text-xl font-medium pt-4">
          {" "}
          What are the shipping charges?
        </h2>{" "}
        <p className="my-4 text-justify">
          All orders ship free under standard shipping.
        </p>
      </div>
    </div>
  );
}

export default ShippingPolicy;
