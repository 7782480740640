import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { getProductDetails } from "../actions/productAction";
import { useParams } from "react-router-dom";
import { addItemsToCart } from "../actions/cartAction";
import Loader from "../components/Loader";
import { backend_url } from "../actions/backendUrl";
import StarRating from "../components/StarRating";
import "./CarouselStyling.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MapPinIcon, XMarkIcon } from "@heroicons/react/24/outline";
import SlidingPanel from "react-sliding-side-panel";
import "react-sliding-side-panel/lib/index.css";
import { Link } from "react-router-dom";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  const params = useParams();

  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );

  useEffect(() => {
    dispatch(getProductDetails(params.urlname));
  }, [dispatch, params.urlname]);

  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => {
    const qty = quantity + 1;
    if (product.stock[index] <= quantity) return;
    setQuantity(qty);
  };
  const decreaseQuantity = () => {
    const qty = quantity - 1;
    if (quantity <= 1) return;
    setQuantity(qty);
  };

  const addToCartHandler = () => {
    dispatch(addItemsToCart(params.urlname, index, quantity));
    setOpenPanel(true);
    // toast.success("Item added to Cart!", {
    //   position: "top-right",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
  };

  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const [pinCode, setPinCode] = useState("");
  const [isAvailVisible, setIsAvailVisible] = useState(false);
  const [weight, setWeight] = useState("");
  const [flavour, setFlavour] = useState("");
  const [backgroundPosition, setBackgroundPosition] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");

  const [openPanel, setOpenPanel] = useState(false);
  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );

  useEffect(() => {
    let temp = [];
    if (product && product.images) {
      for (let i = 0; i < product.images[index].length; i++) {
        temp.push(backend_url + `/uploads/${product.images[index][i]}`);
      }

      setData(temp);

      if (product && product.weight && product.flavour) {
        setWeight(product.weight[index]);
        setFlavour(product.flavour[index]);
      }
    }
  }, [product, index, dispatch]);

  const handlePinCode = (e) => {
    e.preventDefault();
    if (pinCode.length == 6) {
      setIsAvailVisible(true);
    }
  };

  const handleChange = (type, value) => {
    if (type === "flavour") {
      let indexes = [];
      let weights = [];

      setFlavour(value);
      for (let i = 0; i < product.flavour.length; i++) {
        if (product.flavour[i].trim() === value) {
          indexes.push(i);
          weights.push(product.weight[i]);
        }
      }
      if (weights.includes(weight)) {
        setIndex(indexes[weights.indexOf(weight)]);
      } else {
        setIndex(indexes[0]);
      }
    } else if (type === "weight") {
      let indexes = [];
      let flavours = [];

      setWeight(value);
      for (let i = 0; i < product.weight.length; i++) {
        if (product.weight[i] === value) {
          indexes.push(i);
          flavours.push(product.flavour[i]);
        }
      }
      if (flavours.includes(flavour)) {
        setIndex(indexes[flavours.indexOf(flavour)]);
      } else {
        setIndex(indexes[0]);
      }
    }
  };

  const handleMouseMove = (e, src) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBackgroundPosition(`${x}% ${y}%`);
    setBackgroundImage(`url(${src})`);
  };
  return (
    <div className="bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50 p-4 md:p-0 md:py-8">
      <ToastContainer />
      {loading ? (
        <Loader />
      ) : (
        <div className="md:w-5/6 mx-auto">
          <SlidingPanel
            type={"right"}
            isOpen={openPanel}
            size={window.innerWidth > 768 ? 30 : 100}
            backdropClicked={() => setOpenPanel(false)}
          >
            <div className="bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50 min-h-full w-full">
              <button
                className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                onClick={() => setOpenPanel(false)}
                aria-label="close modal"
                role="button"
              >
                <XMarkIcon className="h-6 stroke-[2.5]" />
              </button>
              <div className="py-10">
                <h2 className="text-xl font-semibold text-red-600 text-center">
                  Your Cart Items
                </h2>
                <div className="pt-4">
                  {cartItems &&
                    cartItems.map((item) => (
                      <div className="flex my-4 ml-4 mr-8" key={item.product}>
                        <img
                          src={backend_url + `/uploads/${item.image}`}
                          alt={item.name}
                          className="w-12"
                        />
                        <Link
                          to={`/product/${item.urlname}`}
                          className="my-auto font-medium mx-3"
                        >
                          {item.name}
                        </Link>
                        <span className="text-sm my-auto ml-auto">
                          {item.quantity} X ₹{item.price}&nbsp;=&nbsp;
                          <b>₹{item.price * item.quantity}</b>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div className="pb-6 w-80 mx-auto">
                <h2 className="text-xl font-semibold text-center text-red-600">
                  Order Summary
                </h2>
                <div className="mt-4">
                  <hr className="my-4" />
                  <div className="flex my-2 justify-between">
                    <p className="text-gray-700">Subtotal</p>
                    <p className="text-gray-700">₹ {subtotal.toFixed(2)}</p>
                  </div>
                  <div className="flex my-2 justify-between">
                    <p className="text-gray-700">Shipping</p>
                    <p className="text-gray-700">₹ 0.00</p>
                  </div>
                  <div className="flex my-2 justify-between">
                    <p className="text-gray-700">Tax</p>
                    <p className="text-gray-700">₹ 0.00</p>
                  </div>
                  <hr className="my-4" />
                  <div className="flex justify-between">
                    <p className="font-bold">Total</p>
                    <div className="grid">
                      <p className="mb-1 font-bold">₹ {subtotal.toFixed(2)}</p>
                      <p className="text-sm text-gray-700 justify-self-end">
                        including GST
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => (window.location.href = "/cart")}
                    className="mt-6 w-full rounded-md bg-red-500 py-1.5 font-medium text-red-50 hover:bg-red-600"
                  >
                    View Cart
                  </button>
                </div>
              </div>
            </div>
          </SlidingPanel>
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row">
              <div className="md:flex-1 md:mr-4 mb-4 md:mb-0 justify-center bg-white pt-6">
                <Carousel
                  showStatus={false}
                  // infiniteLoop={true}
                  className="mx-auto"
                >
                  {data?.map((d) => (
                    <figure
                      onMouseMove={(e) => {
                        handleMouseMove(e, d);
                      }}
                      style={{
                        backgroundImage: backgroundImage,
                        backgroundPosition: backgroundPosition,
                      }}
                      className="group hover:cursor-crosshair w-3/5 mx-auto"
                    >
                      <img src={d} className="group-hover:opacity-0 max-h-80" />
                    </figure>
                  ))}
                </Carousel>
              </div>
              <div className="md:flex-1 px-4 md:px-8 bg-white pt-6">
                <div className="mx-auto pb-4">
                  <div className="flex items-center space-x-2 text-gray-500 text-sm">
                    <a href="/" className="hover:underline hover:text-gray-600">
                      Home
                    </a>
                    <span>
                      <svg
                        className="h-5 w-5 leading-none text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </span>
                    <a
                      href={`/products?category=${product.category}`}
                      className="hover:underline hover:text-gray-600"
                    >
                      {product && product.category}
                    </a>
                  </div>
                </div>
                <h2 className="mb-2 leading-tight tracking-tight font-bold text-gray-800 text-2xl md:text-3xl">
                  {product && product.name}
                </h2>
                {/* <p className="text-gray-500 text-sm">By <a href="#" className="text-red-600 hover:underline">ABC Company</a></p> */}

                <span className="flex items-center">
                  <StarRating rating={0} />
                  <span className="text-gray-600 ml-3">
                    {product && product.numofReviews} Reviews
                  </span>
                </span>

                <div className="flex items-center space-x-4 my-4">
                  <div>
                    <div className="rounded-lg border border-gray-400 shadow-2 flex py-2 px-3">
                      <span className="font-bold text-red-600 text-3xl">
                        ₹
                        {product &&
                          product.discountedPrice &&
                          product.discountedPrice[index]}
                      </span>
                      <span className="text-red-400 ml-1 mt-1 line-through self-end">
                        ₹
                        {product &&
                          product.actualPrice &&
                          product.actualPrice[index]}
                      </span>
                    </div>
                  </div>
                  <div className="flex-1 my-4">
                    <p className="text-green-500 text-xl font-semibold">
                      Save{" "}
                      {Math.round(
                        ((product &&
                          product.discountedPrice &&
                          product.actualPrice &&
                          product.actualPrice[index] -
                            product.discountedPrice[index]) *
                          100) /
                          (product &&
                            product.actualPrice &&
                            product.actualPrice[index])
                      )}
                      %
                    </p>
                    <p className="text-gray-400 text-sm">
                      Inclusive of all Taxes.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row my-6 gap-4">
                  <div className="flex text-sm items-center">
                    <span className="mr-3 text-base">Weight</span>
                    <div className="relative">
                      <select
                        onChange={(e) => handleChange("weight", e.target.value)}
                        value={weight}
                        className="rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-red-600 text-base pl-3 pr-10"
                      >
                        {product &&
                          product.weight &&
                          product.weight
                            .filter(
                              (value, index, self) =>
                                self.indexOf(value) === index
                            )
                            .map((w8) => <option>{w8}</option>)}
                      </select>
                      <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          className="w-4 h-4"
                          viewBox="0 0 24 24"
                        >
                          <path d="M6 9l6 6 6-6"></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <span className="md:ml-6 mr-3">Flavour</span>
                    <div className="relative">
                      <select
                        onChange={(e) =>
                          handleChange("flavour", e.target.value)
                        }
                        value={flavour}
                        className="rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-red-600 text-base pl-3 pr-10"
                      >
                        {product &&
                          product.flavour &&
                          product.flavour
                            .filter(
                              (value, index, self) =>
                                self.indexOf(value) === index
                            )
                            .map((flav) => <option>{flav}</option>)}
                      </select>
                      <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          className="w-4 h-4"
                          viewBox="0 0 24 24"
                        >
                          <path d="M6 9l6 6 6-6"></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="md:flex items-center gap-6">
                  <form onSubmit={handlePinCode}>
                    <div className="flex py-2 border rounded border-gray-500 w-56">
                      <MapPinIcon className="w-8 mx-2 text-gray-800" />
                      <input
                        className="w-full outline-none"
                        type="text"
                        pattern="[0-9]{6}"
                        minLength={6}
                        maxLength={6}
                        placeholder="Enter Pincode"
                        value={pinCode}
                        onChange={(e) => setPinCode(e.target.value)}
                      />
                      <button
                        type="submit"
                        className="text-gray-500 hover:text-gray-800 mx-4"
                      >
                        Check
                      </button>
                    </div>
                  </form>
                  {isAvailVisible ? (
                    <p className="text-red-600 mt-2 md:mt-0">
                      Delivered in 2-3 Business Days.
                    </p>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="flex py-6 space-x-4">
                  <div>
                    <label for="Quantity" className="sr-only">
                      {" "}
                      Quantity{" "}
                    </label>

                    <div className="flex items-center border border-gray-200 rounded">
                      <button
                        type="button"
                        className="w-10 h-10 leading-10 text-gray-600 transition hover:opacity-75"
                        onClick={decreaseQuantity}
                      >
                        &minus;
                      </button>

                      <input
                        readOnly
                        type="number"
                        id="Quantity"
                        value={quantity}
                        className="h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
                      />

                      <button
                        type="button"
                        className="w-10 h-10 leading-10 text-gray-600 transition hover:opacity-75"
                        onClick={increaseQuantity}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="px-6 py-2 font-semibold rounded bg-red-600 hover:shadow-md text-white"
                    onClick={addToCartHandler}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-white my-4">
              {product ? (
                <div
                  className="mx-4 md:mx-8 text-justify py-6"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
