import React from "react";
import { Link } from "react-router-dom";
import { backend_url } from "../actions/backendUrl";

function CategoryCard({ image, category }) {
  return (
    <div>
      <Link to={`/products?category=${category}`}>
        <div className="flex flex-col justify-center items-center md:px-8 pt-4 rounded-xl border border-gray-500 hover:border-black">
          <img
            src={backend_url + `/uploads/${image}`}
            alt={category}
            className="h-20 md:h-28"
          />
          <p className="py-2 text-gray-900 font-semibold">{category}</p>
        </div>
      </Link>
    </div>
  );
}

export default CategoryCard;
