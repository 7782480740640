import React, { useEffect, useState } from "react";
import logo from "../static/images/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userAction";
import {
  UserIcon,
  ShoppingCartIcon,
  MagnifyingGlassIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";

const Navbar = () => {
  let navigate = useNavigate();

  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const [searchKeyword, setSearchKeyword] = useState("");
  const [navbarOpen, setNavbarOpen] = useState(false);

  const { success } = useSelector((state) => state.cart);
  // console.log(success);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchKeyword.trim()) {
      navigate("/products?keyword=" + searchKeyword);
    } else {
      navigate("/products");
    }
  };

  return (
    <>
      <div className="sticky hidden md:block top-0 z-10">
        {/* logo */}
        <header className="header sticky top-0 bg-white flex items-center justify-between md:px-8 py-3">
          <div className="md:w-3/12">
            <Link to="/">
              <div className=" flex hover:text-red-600 duration-200 cursor-pointer items-center">
                <img
                  src={logo}
                  alt="FitnessTrendz Logo"
                  className="h-10 mr-6 md:mr-0"
                />
                <h1 className="font-bold text-2xl pl-2 hidden md:block">
                  FitnessTrendz
                </h1>
              </div>
            </Link>
          </div>

          {/* search */}
          <div className="w-10/12 md:w-6/12">
            <form
              onSubmit={handleSearch}
              className="w-5/6 flex items-center mx-auto bg-white rounded-3xl outline outline-gray-300 focus-within:outline-gray-500"
            >
              <div className="w-full">
                <input
                  type="text"
                  className="w-full px-2 md:px-4 py-1 text-gray-800 rounded-full focus:outline-none"
                  placeholder="Search for products..."
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="flex items-center justify-center w-10 h-10 md:w-12 md:h-12 rounded-r-lg"
                >
                  <MagnifyingGlassIcon className="w-5 h-5 md:w-6 md:h-6 text-gray-500 hover:text-gray-700 stroke-[1.8]" />
                </button>
              </div>
            </form>
          </div>

          {/* icons */}
          <div className="md:w-3/12 flex justify-end">
            {isAuthenticated ? (
              <button
                onClick={() => (window.location = "/profile")}
                className="flex p-1 hover:text-red-600 duration-200"
              >
                <UserIcon className="h-6 stroke-[1.8]" />
                <h2 className="font-medium hidden md:block">Profile</h2>
              </button>
            ) : (
              <a
                href="/login"
                className="flex p-1 hover:text-red-600 duration-200"
              >
                <UserIcon className="h-6 stroke-[1.8]" />
                <h2 className="font-medium hidden md:block">Login/Register</h2>
              </a>
            )}

            <div className="w-4/12 flex justify-center">
              <a
                href="/cart"
                className="flex p-1 hover:text-red-600 duration-200"
              >
                <div className="relative">
                  <ShoppingCartIcon className="h-6 stroke-[1.8]" />
                  <span className="absolute -right-1 -top-1 rounded-full bg-red-600 w-4 h-4 text-white text-xs text-center">
                    {localStorage.getItem("cartItems")
                      ? JSON.parse(localStorage.getItem("cartItems")).length
                      : 0}
                  </span>
                </div>
                <h2 className="font-medium px-2 hidden md:block">My Cart</h2>
              </a>
            </div>
          </div>
        </header>

        {/* nav-items */}
        <header className="header hidden md:flex sticky top-0 bg-white items-center justify-center px-8 border-y-2">
          <nav className="nav font-medium text-lg">
            <ul className="flex items-center">
              <li className="p-4 hover:text-red-600 duration-200 cursor-pointer">
                <a href="/?shopbycategory">Shop By Category</a>
              </li>
              <li className="p-4 hover:text-red-600 duration-200 cursor-pointer">
                <a href="/products">All Products</a>
              </li>
              <li className="p-4 hover:text-red-600 duration-200 cursor-pointer">
                <a href="https://blogs.fitnesstrendz.in/" target="_blank">
                  Blogs
                </a>
              </li>
              <li className="p-4 hover:text-red-600 duration-200 cursor-pointer">
                <a href="/aboutus">About Us</a>
              </li>
              <li className="p-4 hover:text-red-600 duration-200 cursor-pointer">
                <a href="/certificates">Certificates</a>
              </li>
              {/* <li className="p-4 border-b-2 border-red-600 border-opacity-0 hover:text-red-600 duration-200 cursor-pointer">
              <a href="">Contact Us</a>
            </li> */}
            </ul>
          </nav>
        </header>
      </div>

      {/* Mobile */}
      <div className="sticky md:hidden block top-0 z-10 border-b-2">
        {/* logo */}
        <header className="header sticky top-0 bg-white flex items-center justify-between px-4 py-3">
          <div onClick={() => setNavbarOpen(!navbarOpen)}>
            <Bars3Icon className="h-6 stroke-[1.8]" />
          </div>
          <div className="">
            <Link to="/">
              <div className=" flex hover:text-red-600 duration-200 cursor-pointer items-center justify-center">
                <img src={logo} alt="FitnessTrendz Logo" className="h-10 " />
                <h1 className="font-bold text-2xl pl-1">FitnessTrendz</h1>
              </div>
            </Link>
          </div>

          {/* icons */}
          <div className="flex justify-end">
            {isAuthenticated ? (
              <button
                onClick={() => (window.location = "/profile")}
                className="flex p-1 hover:text-red-600 duration-200"
              >
                <UserIcon className="h-6 stroke-[1.8]" />
                <h2 className="font-medium hidden md:block">Profile</h2>
              </button>
            ) : (
              <a
                href="/login"
                className="flex p-1 hover:text-red-600 duration-200"
              >
                <UserIcon className="h-6 stroke-[1.8]" />
                <h2 className="font-medium hidden md:block">Login/Register</h2>
              </a>
            )}

            <div className="w-4/12 flex justify-center">
              <a
                href="/cart"
                className="flex p-1 hover:text-red-600 duration-200"
              >
                <div className="relative">
                  <ShoppingCartIcon className="h-6 stroke-[1.8]" />
                  <span className="absolute -right-1 -top-1 rounded-full bg-red-600 w-4 h-4 text-white text-xs text-center">
                    {localStorage.getItem("cartItems")
                      ? JSON.parse(localStorage.getItem("cartItems")).length
                      : 0}
                  </span>
                </div>
                {/* <h2 className="font-medium px-2 hidden md:block">My Cart</h2> */}
              </a>
            </div>
          </div>
        </header>

        {/* nav-items */}
        <header
          className={
            "header sticky top-0 bg-white items-center justify-center border-y-2 " +
            (navbarOpen ? "block" : "hidden")
          }
        >
          <nav className="nav font-medium text-lg">
            <ul className="flex flex-col items-center divide-y">
              <li className="p-3 w-full text-center hover:text-red-600 duration-200 cursor-pointer">
                <a href="/?shopbycategory">Shop By Category</a>
              </li>
              <li className="p-3 w-full text-center hover:text-red-600 duration-200 cursor-pointer">
                <a href="/products">All Products</a>
              </li>
              <li className="p-3 w-full text-center hover:text-red-600 duration-200 cursor-pointer">
                <a href="https://blogs.fitnesstrendz.in/" target="_blank">
                  Blogs
                </a>
              </li>
              <li className="p-3 w-full text-center hover:text-red-600 duration-200 cursor-pointer">
                <a href="/aboutus">About Us</a>
              </li>
              <li className="p-3 w-full text-center hover:text-red-600 duration-200 cursor-pointer">
                <a href="/certificates">Certificates</a>
              </li>
              {/* <li className="p-4 border-b-2 border-red-600 border-opacity-0 hover:text-red-600 duration-200 cursor-pointer">
              <a href="">Contact Us</a>
            </li> */}
            </ul>
          </nav>
        </header>

        {/* search */}
      </div>
      <div className="w-11/12 py-4 mx-auto block md:hidden">
        <form
          onSubmit={handleSearch}
          className="flex items-center mx-auto bg-white rounded-3xl outline outline-gray-300 focus-within:outline-gray-500"
        >
          <div className="w-full">
            <input
              type="text"
              className="w-full px-2 md:px-4 py-1 text-gray-800 rounded-full focus:outline-none"
              placeholder="Search for products..."
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
          <div>
            <button
              type="submit"
              className="flex items-center justify-center w-10 h-10 md:w-12 md:h-12 rounded-r-lg"
            >
              <MagnifyingGlassIcon className="w-5 h-5 md:w-6 md:h-6 text-gray-500 hover:text-gray-700 stroke-[1.8]" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Navbar;
